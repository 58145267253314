import { Typography } from "@mui/joy";
import Button from "@mui/joy/Button";
import Box from "@mui/joy/Box";
import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Sidebar from "../joy/components/Sidebar";
import Header from "../joy/components/Header";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

//local imports
import TimecardTable from "../joy/components/TimecardTable";
import TimecardList from "../joy/components/TimecardList";

function TestDataGridPage({ location }) {
	return (
		<CssVarsProvider disableTransitionOnChange>
			<CssBaseline />

			<Box sx={{ display: "flex", minHeight: "100dvh" }}>
				<Header />
				<Sidebar location={location} />

				<Box
					component="main"
					className="MainContent"
					sx={{
						px: { xs: 2, md: 6 },
						pt: {
							xs: "calc(12px + var(--Header-height))",
							sm: "calc(12px + var(--Header-height))",
							md: 3,
						},
						pb: { xs: 2, sm: 2, md: 3 },
						flex: 1,
						display: "flex",
						flexDirection: "column",
						minWidth: 0,
						height: "70dvh",
						gap: 1,
					}}
				>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Breadcrumbs
							size="sm"
							aria-label="breadcrumbs"
							separator={<ChevronRightRoundedIcon fontSize="sm" />}
							sx={{ pl: 0 }}
						>
							<Link
								underline="none"
								color="neutral"
								href="#some-link"
								aria-label="Home"
							>
								<HomeRoundedIcon />
							</Link>
							<Link
								underline="hover"
								color="neutral"
								href="#some-link"
								sx={{ fontSize: 12, fontWeight: 500 }}
							>
								Timecards
							</Link>
							<Typography
								color="primary"
								sx={{ fontWeight: 500, fontSize: 12 }}
							>
								Manage
							</Typography>
						</Breadcrumbs>
					</Box>
					<Box
						sx={{
							display: "flex",
							mb: 1,
							gap: 1,
							flexDirection: { xs: "column", sm: "row" },
							alignItems: { xs: "start", sm: "center" },
							flexWrap: "wrap",
							justifyContent: "space-between",
						}}
					>
						<Typography level="h2" component="h1">
							Manage
						</Typography>
						<Button
							color="primary"
							startDecorator={<DownloadRoundedIcon />}
							size="sm"
							disabled
						>
							Download PDF
						</Button>
						<TimecardTable />
						<TimecardList />
					</Box>
				</Box>
			</Box>
		</CssVarsProvider>
	);
}

export default TestDataGridPage;
