import * as React from "react";
import Sheet from "@mui/joy/Sheet";
import { Typography } from "@mui/joy";
import Box from "@mui/joy/Box";

const MobilePayrollCreate = () => {
	return (
		<Sheet
			className="OrderTableContainer"
			variant="outlined"
			sx={{
				display: { xs: "block", sm: "none" },
				width: "100%",
				borderRadius: "sm",
				flexShrink: 1,
				overflow: "auto",
				minHeight: 0,
			}}
		>
			<Typography>Mobile</Typography>
		</Sheet>
	);
};

export default MobilePayrollCreate;
