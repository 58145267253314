import { useState, useEffect } from "react";
import Personnel from "../personnel";
import { Box } from "@mui/material";
import TopBarAdmin from "../components/TopBarAdmin";
import usePath from "../hooks/fetchPath";
import { useAuth } from "../context/AuthProvider";
import Alert from "@mui/material/Alert";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";

function AdminPagePersonnel({ auth }) {
	const { api } = useAuth();
	const [setIsSidebar] = useState(true);
	const [selectedDataID] = useState([]);
	const { baseURL } = usePath("/api/v1/personnel");
	const [notificationsNumber, setnotificationsNumber] = useState(0);
	const [pageState, setPageState] = useState({
		isLoading: false,
		data: [],
		total: 0,
		page: 0,
		pageSize: 50,
	});
	const [errState, setErrState] = useState(false);
	const [errMsg, seterrMsg] = useState("");
	const [listData, setListData] = useState([]);

	useEffect(() => {
		async function GetResults() {
			try {
				setErrState(false);
				seterrMsg("");
				const response = await api.get(
					`/api/v1/timecardsv2/unapproved/?page=1&limit=1000`
				);

				setnotificationsNumber(response.data.data[0].results.length);
			} catch (err) {
				console.log("Failed", { err });
				setErrState(true);
				seterrMsg("Error Loading Data");
			}
		}
		GetResults();
	}, [api]);

	useEffect(() => {
		if (api.authenticated) {
			const pageLimit = `&limit=${pageState.pageSize}`;
			api
				.get(
					`/api/v1/personnel?sort=fullName&page=${pageState.page + 1}` +
						pageLimit
				)
				.then((response) => {
					setErrState(false);
					seterrMsg("");
					function padDigits(number, digits) {
						return (
							Array(Math.max(digits - String(number).length + 1, 0)).join(0) +
							number
						);
					}

					if (response) {
						response.data.data.map((element, i) => ({
							...element,
							employeeID: padDigits(i + 151, 4),
						}));
					}

					const update = {
						data: response.data.data,
						pageSize: pageState.pageSize,
						total: response.data.total,
					};

					setPageState((old) => ({
						...old,
						...update,
					}));
				})
				.catch((err) => {
					console.log("FAILED", { err });
					setErrState(true);
					seterrMsg("Error Loading Data");
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [baseURL, pageState.page, pageState.pageSize, api]);

	//map through an array and delete many users
	async function deleteManyUsers(...prevPayroll) {
		await Promise.all(
			selectedDataID.map((id) =>
				api.delete("/api/v1/auth/users/" + id, {
					baseURL: baseURL,
				})
			)
		);
	}

	//Get List Data

	function checkFailed(then) {
		return function (responses) {
			const someFailed = responses.some((response) => response.error);

			if (someFailed) {
				throw responses;
			}

			return then(responses);
		};
	}
	useEffect(() => {
		if (api.authenticated) {
			Promise.all([
				api.get("/api/v1/ratebook/craftlist"),
				api.get("/api/v1/ratebook/classificationlist"),
			])
				.then(
					checkFailed(([craft, classification, book, project]) => {
						setListData((prev) => ({
							...prev,
							craft,
							classification,
							book,
							project,
						}));
					})
				)
				.catch((err) => {
					console.log("FAIL", err);
					setErrState(true);
					seterrMsg("Error Loading Data");
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api]);

	return (
		<Box>
			{errState ? (
				<Alert
					icon={<RunningWithErrorsIcon fontSize="inherit" />}
					severity="error"
				>
					{errMsg}
				</Alert>
			) : null}
			<TopBarAdmin notificationsNumber={notificationsNumber} />
			<Personnel
				pageState={pageState}
				setPageState={setPageState}
				selectedDataID={selectedDataID}
				deleteManyUsers={deleteManyUsers}
				listData={listData}
			/>
		</Box>
	);
}

export default AdminPagePersonnel;
