import { Typography, Stack, useTheme } from '@mui/material';
import { tokens } from '../theme';

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Stack spacing={0.1}>
      <Typography
        variant='h9'
        color={colors.grey[100]}
        sx={{ mb: '5px' }}
        fontWeight='bold'>
        {title}
      </Typography>
      <Typography variant='h9' color={colors.greenAccent[400]}>
        {subtitle}
      </Typography>
    </Stack>
  );
};

export default Header;
