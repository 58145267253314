import dayjs from 'dayjs';

export const checkTimeStartErrors = (log) => {
  let errState = false;
  let errMsg = '';
  let id;
  console.log(log, 'log');
  for (let i = 0; i < log.length; i++) {
    let start;
    let end;
    if (log[i].start && log[i].end) {
      start = dayjs(log[i].start);
      end = dayjs(log[i].end);

      //if start time is greater than end time
      if (start.diff(end) > 0 || start.diff(end) === 0) {
        errState = true;
        errMsg = 'Check Start and End Time at ' + log[i].id;
        id = log[i].id;
        console.log('breaking out of log loop');
        break;
      }
    }

    if (
      (log[i].start !== null && log[i].end === null) ||
      (log[i].start === null && log[i].end !== null)
    ) {
      errState = true;
      errMsg = 'Cannot leave start without a paired end time ' + log[i].id;
      id = log[i].id;
      console.log('breaking out of log loop');
      break;
    }
  }
  return { errState, errMsg, id };
};
