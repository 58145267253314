import * as React from "react";
import Sheet from "@mui/joy/Sheet";
import { Typography } from "@mui/joy";
import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";

const DesktopPayrollCreate = () => {
	return (
		<Sheet
			className="OrderTableContainer"
			variant="outlined"
			sx={{
				display: { xs: "none", sm: "initial" },
				width: "100%",
				borderRadius: "sm",
				flexShrink: 1,
				overflow: "auto",
				minHeight: 0,
			}}
		>
			<Tabs defaultValue={0} sx={{ bgcolor: "transparent" }}>
				<TabList
					tabFlex={1}
					size="sm"
					sx={{
						pl: { xs: 0, md: 4 },
						justifyContent: "left",
						[`&& .${tabClasses.root}`]: {
							fontWeight: "600",
							flex: "initial",
							color: "text.tertiary",
							[`&.${tabClasses.selected}`]: {
								bgcolor: "transparent",
								color: "text.primary",
								"&::after": {
									height: "2px",
									bgcolor: "primary.500",
								},
							},
						},
					}}
				>
					<Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={0}>
						1 project
					</Tab>
					<Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={1}>
						2 projects
					</Tab>
					<Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={2}>
						3 projects
					</Tab>
				</TabList>
			</Tabs>
			<Box sx={{ p: 2 }}>
				<Stack direction="row" spacing={1}>
					<Box sx={{ backgroundColor: "red", width: "20%" }}>
						<Typography level="body-xs">Week Ending</Typography>
					</Box>
					<Box sx={{ backgroundColor: "blue", width: "20%" }}>
						<Typography level="body-xs">Project 1</Typography>
					</Box>
					<Box sx={{ backgroundColor: "red", width: "20%" }}>
						<Typography level="body-xs">Project 2</Typography>
					</Box>
					<Box sx={{ backgroundColor: "blue", width: "20%" }}>
						<Typography level="body-xs">Project 3</Typography>
					</Box>
					<Box sx={{ backgroundColor: "red", width: "20%" }}>
						<Typography level="body-xs">Totals</Typography>
					</Box>
				</Stack>
			</Box>
		</Sheet>
	);
};

export default DesktopPayrollCreate;
