import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AddIcon from "@mui/icons-material/Add";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PunchClockIcon from "@mui/icons-material/PunchClock";
//import EmailIcon from '@mui/icons-material/Email';
import CalculateIcon from "@mui/icons-material/Calculate";
//import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
//import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import ReportIcon from "@mui/icons-material/Report";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

import { useAuth } from "../context/AuthProvider";

//args, 1) size 2) alignment 3)font
const CustomTypography = ({ size, variant, label }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const theme2 = createTheme({
		typography: {
			body1: {
				fontSize: ".75rem",
				"@media (min-width:600px)": {
					fontSize: "1rem",
				},
				"@media (min-width:900px)": {
					fontSize: "1.25rem",
				},
				color: colors.grey[100],
			},
			body2: {
				fontSize: "0.65rem",
				"@media (min-width:600px)": {
					fontSize: ".80rem",
				},
				"@media (min-width:900px)": {
					fontSize: "0.9rem",
				},
				// fontWeight: 'bold',
				color: colors.greenAccent[600],
				marginTop: "5px",
				marginLeft: "1rem",
			},
			Header: {
				fontSize: "1.3rem",
				"@media (min-width:600px)": {
					fontSize: "1.40rem",
				},
				"@media (min-width:900px)": {
					fontSize: "1.50rem",
				},
				color: colors.greenAccent[300],
				marginTop: "5px",
				marginLeft: "10px",
			},
		},
	});

	return (
		<ThemeProvider theme={theme2}>
			<Typography variant={variant}>{label}</Typography>
		</ThemeProvider>
	);
};

const Item = ({ title, to, icon, selected, setSelected }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	return (
		<MenuItem
			active={selected === title}
			style={{
				color: colors.grey[100],
			}}
			onClick={() => setSelected(title)}
			icon={icon}
		>
			<Typography variant="body2" sx={{ mt: 0.1, mb: 2 }}>
				{title}
			</Typography>
			<Link to={to} />
			{/* <Routes>
      <Route path={to} ></Route>
      </Routes> */}
		</MenuItem>
	);
};

const SideBarAdmin = ({ isSidebar, height }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [isCollapsed, setIsCollapsed] = useState(false);
	const [selected, setSelected] = useState();
	const { user } = useAuth();

	useEffect(() => {
		//Runs only on the first render
		setSelected("Dashboard");
	}, []);

	return (
		isSidebar && (
			<Box
				sx={{
					"& .pro-sidebar-inner": {
						background: `${colors.primary[400]} !important`,
					},
					"& .pro-icon-wrapper": {
						backgroundColor: "transparent !important",
					},
					"& .pro-inner-item": {
						padding: "5px 35px 5px 20px !important",
					},
					"& .pro-inner-item:hover": {
						color: "#868dfb !important",
					},
					"& .pro-menu-item.active": {
						color: "#6870fa !important",
					},
					"& .pro-item-content": {
						margin: "0 0 -15px 0 !important",
					},
					height: `${height}+px`,
					display: `${isSidebar ? "block" : "none"}`,
				}}
			>
				<ProSidebar collapsed={isCollapsed}>
					<Menu iconShape="square">
						{/* LOGO AND MENU ICON */}
						<MenuItem
							onClick={() => setIsCollapsed(!isCollapsed)}
							icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
							style={{
								margin: "10px 0 20px 0",
								color: colors.grey[100],
							}}
						>
							{!isCollapsed && (
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									ml="15px"
								>
									<Typography variant="body1">ADMIN</Typography>
									<IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
										<MenuOutlinedIcon />
									</IconButton>
								</Box>
							)}
						</MenuItem>

						{!isCollapsed && (
							<Box mb="25px">
								<Box textAlign="center">
									<CustomTypography
										variant="Header"
										label={[user?.firstName, user?.lastName].join(" ")}
									></CustomTypography>
									<CustomTypography
										variant="body2"
										label="Admin Control Panel"
									></CustomTypography>
								</Box>
							</Box>
						)}

						<Box paddingLeft={isCollapsed ? undefined : "10%"}>
							<CustomTypography variant="body2" label="Home"></CustomTypography>
							<Item
								title="Dashboard"
								to="/Dashboard"
								icon={<HomeOutlinedIcon />}
								selected={selected}
								setSelected={setSelected}
							/>

							<CustomTypography
								variant="body2"
								label="Data Entry"
							></CustomTypography>
							<Item
								title="Create"
								to="/PayrollCreatePage"
								icon={<AddIcon />}
								selected={selected}
								setSelected={setSelected}
							/>
							<Item
								title="Time Clock"
								to="/TimeClockPage"
								icon={<PunchClockIcon />}
								selected={selected}
								setSelected={setSelected}
							/>
							<CustomTypography
								variant="body2"
								label="Manage"
							></CustomTypography>
							<Item
								title="Testing"
								to="/TestGroundsPage"
								icon={<QuestionMarkIcon />}
								selected={selected}
								setSelected={setSelected}
							/>
							<Item
								title="Timecards"
								to="/AdminPageTimecards"
								icon={<ReceiptOutlinedIcon />}
								selected={selected}
								setSelected={setSelected}
							/>
							<Item
								title="Users"
								to="/AdminPageUsers"
								icon={<PeopleOutlinedIcon />}
								selected={selected}
								setSelected={setSelected}
							/>
							<Item
								title="Projects"
								to="/AdminPageProjects"
								icon={<ContactsOutlinedIcon />}
								selected={selected}
								setSelected={setSelected}
							/>
							<Item
								title="RFI"
								to="/AdminPageRFI"
								icon={<HelpCenterIcon />}
								selected={selected}
								setSelected={setSelected}
							/>
							<Item
								title="Change Order"
								to="/AdminChangeOrders"
								icon={<ReportIcon />}
								selected={selected}
								setSelected={setSelected}
							/>
							{/* <Item
                title='Email'
                to='/Email'
                icon={<EmailIcon />}
                selected={selected}
                setSelected={setSelected}
              /> */}
							<Item
								title="Personnel"
								to="/AdminPagePersonnel"
								icon={<PersonSearchIcon />}
								selected={selected}
								setSelected={setSelected}
							/>
							<CustomTypography
								variant="body2"
								label="Payroll"
							></CustomTypography>
							<Item
								title="Map"
								to="/Map"
								icon={<PersonSearchIcon />}
								selected={selected}
								setSelected={setSelected}
							/>
							<Item
								title="RateBook"
								to="/RatebookPage"
								icon={<CalculateIcon />}
								selected={selected}
								setSelected={setSelected}
							/>

							<Item
								title="Process Payroll"
								to="/PayrollProcessPage"
								icon={<AttachMoneyIcon />}
								selected={selected}
								setSelected={setSelected}
							/>

							<CustomTypography
								variant="body2"
								label="Settings"
							></CustomTypography>
							<Item
								title="Settings"
								to="/settings"
								icon={<ManageAccountsIcon />}
								selected={selected}
								setSelected={setSelected}
							/>

							<CustomTypography
								variant="body2"
								label="Logout"
							></CustomTypography>
							<Item
								title="Logout"
								to="/Logout"
								icon={<ExitToAppIcon />}
								selected={selected}
								setSelected={setSelected}
							/>
						</Box>
					</Menu>
				</ProSidebar>
			</Box>
		)
	);
};

export default SideBarAdmin;
