export function updateArrayByIndexStart(
  oldValue,
  label,
  index,
  newElement,
  setArray
) {
  const oldArray = [...oldValue];
  switch (label) {
    case 'Start:':
      //only changing start field

      const obj = {
        id: oldArray[index].id,
        project: oldArray[index].project,
        start: newElement,
        end: oldArray[index].end,
        reg: oldArray[index].reg,
        OT: oldArray[index].OT,
        referenceDate: oldArray[index].referenceDate,
      };
      console.log('obj', obj);
      console.log(`Old value ${oldArray.start} to new value ${obj.start}`);
      oldArray.splice(index, 1, obj);
      return oldArray;

    case 'End:':
      //only changing end field
      const obj1 = {
        id: oldArray[index].id,
        project: oldArray[index].project,
        start: oldArray[index].start,
        end: newElement,
        reg: oldArray[index].reg,
        OT: oldArray[index].OT,
        referenceDate: oldArray[index].referenceDate,
      };
      oldArray.splice(index, 1, obj1);
      return oldArray;

    default:
      console.log('Error');
  }
}

//array is the project log
//day ie Monday
export function updateProjectArrayByDay(array, day, update) {
  //project array has 7 objects, 1 for each day
  const cloneArray = [...array];
  if (day === 'Sunday') {
    cloneArray.splice(0, 1, update);
  } else if (day === 'Monday') {
    cloneArray.splice(1, 1, update);
  } else if (day === 'Tuesday') {
    cloneArray.splice(2, 1, update);
  } else if (day === 'Wednesday') {
    cloneArray.splice(3, 1, update);
  } else if (day === 'Thursday') {
    cloneArray.splice(4, 1, update);
  } else if (day === 'Friday') {
    cloneArray.splice(5, 1, update);
  } else if (day === 'Saturday') {
    cloneArray.splice(6, 1, update);
  }

  //output final arrayCopy with update
  return cloneArray;
}

export function updateReferenceDates(array, update) {}
