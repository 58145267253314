import { useState, useEffect } from 'react';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import { tokens } from '../theme';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useAuth } from '../context/AuthProvider';
import AddIcon from '@mui/icons-material/Add';
//import PunchClockIcon from '@mui/icons-material/PunchClock';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import ReportIcon from '@mui/icons-material/Report';

//args, 1) size 2) alignment 3)font
const CustomTypography = ({ size, variant, label }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const theme2 = createTheme({
    typography: {
      body1: {
        fontSize: '.75rem',
        '@media (min-width:600px)': {
          fontSize: '1rem',
        },
        '@media (min-width:900px)': {
          fontSize: '1.25rem',
        },
        color: colors.grey[100],
      },
      body2: {
        fontSize: '0.65rem',
        '@media (min-width:600px)': {
          fontSize: '.90rem',
        },
        '@media (min-width:900px)': {
          fontSize: '1rem',
        },
        color: colors.greenAccent[400],
        marginTop: '5px',
        marginLeft: '1.3rem',
      },
      body3: {
        fontSize: '0.55rem',
        '@media (min-width:600px)': {
          fontSize: '.85rem',
        },
        '@media (min-width:900px)': {
          fontSize: '.95rem',
        },
        fontWeight: 'bold',
        color: colors.greenAccent[600],
        marginTop: '5px',
        marginLeft: '1.3rem',
      },
      Header: {
        fontSize: '1.5rem',
        '@media (min-width:600px)': {
          fontSize: '1.60rem',
        },
        '@media (min-width:900px)': {
          fontSize: '1.70rem',
        },
        color: colors.blueAccent[400],
        marginTop: '5px',
        marginLeft: '10px',
      },
    },
  });

  return (
    <ThemeProvider theme={theme2}>
      <Typography variant={variant}>{label}</Typography>
    </ThemeProvider>
  );
};

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}>
      <Typography sx={{ mt: 0.1, mb: 2 }}>{title}</Typography>
      <Link to={to} />
      {/* <Routes>
      <Route path={to} ></Route>
      </Routes> */}
    </MenuItem>
  );
};

const SideBarPM = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();
  const [selected, setSelected] = useState();
  const { user, setUser } = useAuth();
  const [name] = useState(user.lastName + ', ' + user.firstName);

  useEffect(() => {
    //Runs only on the first render
    setSelected('Dashboard');
  }, []);

  return (
    <Box
      sx={{
        '& .pro-sidebar-inner': {
          background: `${colors.primary[400]} !important`,
        },
        '& .pro-icon-wrapper': {
          backgroundColor: 'transparent !important',
        },
        '& .pro-inner-item': {
          padding: '5px 35px 5px 20px !important',
        },
        '& .pro-inner-item:hover': {
          color: '#868dfb !important',
        },
        '& .pro-menu-item.active': {
          color: '#6870fa !important',
        },
        '& .pro-item-content': {
          margin: '0 0 -15px 0 !important',
        },
      }}>
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape='square'>
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: '10px 0 20px 0',
              color: colors.grey[100],
            }}>
            {!isCollapsed && (
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                ml='15px'>
                <Typography variant='body1'>{user.role}</Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb='25px'>
              <Box textAlign='center'>
                <CustomTypography
                  variant='Header'
                  label={name}></CustomTypography>
                <CustomTypography
                  variant='body2'
                  label='PM Control Panel'></CustomTypography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : '10%'}>
            <CustomTypography variant='body3' label='Home'></CustomTypography>
            <Item
              title='Dashboard'
              to='/Dashboard'
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <CustomTypography variant='body3' label='Pages'></CustomTypography>
            <Item
              title='Projects'
              to='/PMPageProjects'
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title='Timecards'
              to='/PMPageTimecards'
              icon={<PendingActionsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title='Create'
              to='/PayrollCreatePage'
              icon={<AddIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title='Time Clock'
              to='/TimeClockPage'
              icon={<PunchClockIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            <Item
              title='RFI'
              to='/AdminPageRFI'
              icon={<HelpCenterIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title='Change Order'
              to='/AdminChangeOrders'
              icon={<ReportIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <CustomTypography
              variant='body2'
              label='Settings'></CustomTypography>
            <Item
              title='Settings'
              to='/settings'
              icon={<ManageAccountsIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <CustomTypography variant='body3' label='Logout'></CustomTypography>
            <Item
              title='Logout'
              to='/Logout'
              icon={<ExitToAppIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default SideBarPM;
