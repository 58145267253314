import React, { useState, useEffect } from "react";
import {
	Stack,
	Grid,
	Typography,
	Box,
	Button,
	useTheme,
	TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import dayjs from "dayjs";
import { tokens } from "../theme";
import { updateArrayByIndexStart } from "../utils/replaceObjectInArray";
import {
	calculateObjectsInArray,
	calculateWeekendHoursArray,
} from "../utils/calculateObjectsInArray";
import { checkTimeStartErrors } from "./../utils/checkErrorsInPayrollCreate";
import useMediaQuery from "@mui/material/useMediaQuery";

const PayrollCreateRow = ({
	day,
	date,
	alternateColors,
	log,
	setLog,
	format,
	shorthand,
	showRowColor1,
	showRowColor2,
	regHours,
	setRegHours,
	OTHours,
	setOTHours,
	SatHours,
	setSatHours,
	SunHours,
	setSunHours,
	seterrState,
	seterrMsg,
}) => {
	//themes
	const breakDuration = Number(0.5);
	const breakHour = Number(5);
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const altColor1 =
		alternateColors === "true" ? colors.grey[900] : colors.grey[800];
	const grey = alternateColors === "true" ? colors.grey[900] : colors.grey[800];
	const [subtotal, setSubtotal] = useState(
		Number(regHours) > breakHour
			? Number(regHours) + breakDuration
			: Number(regHours)
	);
	const [startSlot0, setStartSlot0] = useState(log[0].start);
	const [endSlot0, setEndSlot0] = useState(log[0].end);
	const [startSlot1, setStartSlot1] = useState(log[1].start);
	const [endSlot1, setEndSlot1] = useState(log[1].end);
	const [startSlot2, setStartSlot2] = useState(log[2].start);
	const [endSlot2, setEndSlot2] = useState(log[2].end);
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const isBelowMedium = useMediaQuery(theme.breakpoints.down("md"));

	useEffect(() => {
		setStartSlot0(log[0].start);
		setEndSlot0(log[0].end);
		setStartSlot1(log[1].start);
		setEndSlot1(log[1].end);
		setStartSlot2(log[2].start);
		setEndSlot2(log[2].end);
	}, [log]);

	useEffect(() => {
		setSubtotal(
			Number(regHours) > breakHour
				? Number(regHours) + breakDuration
				: Number(regHours)
		);
	}, [regHours]);

	//index of array [log]
	const CreateTimePickerComponent = ({
		day,
		label,
		index,
		value,
		setvalue,
		log,
		setLog,
		format,
		referenceDate,
	}) => {
		const referenceDateFormatted = dayjs(referenceDate, "MM/DD/YYYY");
		const [date, setDate] = useState(value);
		const [pickerIsOpen, setPickerIsOpen] = useState(false);
		return (
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
				sx={{ minHeight: "100%" }}
			>
				<Grid item xs={3}>
					<Stack direction="row" spacing="1rem">
						<Box width="20px">
							<Typography
								variant="body2"
								sx={{
									pt: 1,
									color:
										label === "Start:" ? colors.grey[400] : colors.grey[500],
								}}
							>
								{" "}
								{label}
							</Typography>
						</Box>
						<Box width={isSmallScreen ? "70px" : "60%"} sx={{ pt: "0.5rem" }}>
							<DesktopTimePicker
								{...value}
								value={date}
								referenceDate={referenceDateFormatted}
								ampm={false}
								slotProps={{
									actionBar: {
										actions: ["cancel", "accept"],
									},
								}}
								open={pickerIsOpen}
								onOpen={() => setPickerIsOpen(true)}
								onClose={() => setPickerIsOpen(false)}
								renderInput={(params) => (
									<TextField
										{...params}
										InputProps={{
											...params.InputProps,
											endAdornment: null, // Remove the clock icon
											sx: {
												paddingRight: "0px", // Remove the extra padding
											},
										}}
									/>
								)}
								onChange={(e) => {
									setDate(e);
								}}
								//can accept e, update params
								onAccept={(e) => {
									const date = e;
									setvalue(date);
									const update = updateArrayByIndexStart(
										log,
										label,
										index,
										date
									);
									//passing in update [array] that contain new datetime in the start and end fields
									switch (format) {
										case "weekday":
											const [sum, reg, OT, finalWeekdayLog] =
												calculateObjectsInArray(update);

											setSubtotal(`${Math.round(sum * 100) / 100}`);
											setRegHours(`${Math.round(reg * 100) / 100}`);
											setOTHours(`${Math.round(OT * 100) / 100}`);
											setLog(finalWeekdayLog);
											const { errState, errMsg, id } =
												checkTimeStartErrors(update);

											seterrState(errState);
											seterrMsg(errMsg);
											break;

										case "saturday":
											const [saturdayHours, finalSaturdayLog] =
												calculateWeekendHoursArray(update);
											setSatHours(`${Math.round(saturdayHours * 100) / 100}`);
											setLog(finalSaturdayLog);
											break;

										case "sunday":
											const [sundayHours, finalSundayLog] =
												calculateWeekendHoursArray(update);
											setSunHours(`${Math.round(sundayHours * 100) / 100}`);
											setLog(finalSundayLog);
											break;

										default:
											break;
									}
								}}
								sx={{
									"& .MuiInputBase-input": {
										paddingRight: "0px",
									},
									"& .MuiOutlinedInput-input": {
										m: 0,
										p: 0,
										pl: 0.35,
										paddingRight: "0px",
										color: colors.grey[100],
										fontSize: "0.75rem",
									},
									"& .MuiSvgIcon-root": {
										display: isBelowMedium ? "none" : "block", // Hide the clock icon via CSS
									},
									"& .MuiOutlinedInput-root": {
										"& fieldset": {
											borderColor: "gray", // Set default border color
										},
										"&:hover fieldset": {
											borderColor: "gray", // Remove hover border effect
										},
										"&.Mui-focused fieldset": {
											borderColor: "gray", // Set border color when focused
										},
									},

									m: 0,
									p: 0,
								}}
							/>
						</Box>
					</Stack>
				</Grid>
			</Grid>
		);
	};

	//function to return Typography based on format
	function formatTypography() {
		switch (format) {
			case "weekday":
				return (
					<Box>
						<Stack direction="row">
							<Typography
								variant="body2"
								sx={{ color: colors.grey[300], pr: 0.5 }}
							>
								Gross:{" "}
							</Typography>
							<Typography
								variant="body2"
								sx={{
									color:
										subtotal > 0 ? colors.greenAccent[500] : colors.grey[300],
								}}
							>
								{subtotal}
							</Typography>
						</Stack>
						<Stack direction="row">
							<Typography
								variant="body2"
								sx={{ color: colors.grey[300], pr: 0.5 }}
							>
								Reg:{" "}
							</Typography>
							<Typography
								variant="body2"
								sx={{
									color:
										regHours > 0 ? colors.greenAccent[500] : colors.grey[300],
									pr: 0.5,
								}}
							>
								{" "}
								{regHours}
							</Typography>
						</Stack>
						<Stack direction="row">
							<Typography
								variant="body2"
								sx={{ color: colors.grey[300], pr: 0.5 }}
							>
								OT:{" "}
							</Typography>
							<Typography
								variant="body2"
								sx={{
									color:
										OTHours > 0 ? colors.greenAccent[500] : colors.grey[300],
									pr: 0.5,
								}}
							>
								{" "}
								{OTHours}{" "}
							</Typography>
						</Stack>
					</Box>
				);
			case "saturday":
				return (
					<Box>
						<Stack direction="row">
							<Typography
								variant="body2"
								sx={{ color: colors.grey[300], pr: 0.5 }}
							>
								Sat:{" "}
							</Typography>
							<Typography
								variant="body2"
								sx={{
									color:
										SatHours > 0 ? colors.greenAccent[500] : colors.grey[300],
									pr: 0.5,
								}}
							>
								{" "}
								{SatHours}{" "}
							</Typography>
						</Stack>
					</Box>
				);
			case "sunday":
				return (
					<Box>
						<Stack direction="row">
							<Typography
								variant="body2"
								sx={{ color: colors.grey[300], pr: 0.5 }}
							>
								Sun:{" "}
							</Typography>
							<Typography
								variant="body2"
								sx={{
									color:
										SunHours > 0 ? colors.greenAccent[500] : colors.grey[300],
									pr: 0.5,
								}}
							>
								{" "}
								{SunHours}{" "}
							</Typography>
						</Stack>
					</Box>
				);
			default:
				break;
		}
	}

	return (
		<Grid
			container
			spacing={1}
			justifyContent="center"
			wrap="nowrap"
			sx={{ mx: 1, px: 1, py: 0.3, minWidth: "75px" }}
		>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				{/* Column 1 Start */}
				<Grid
					item
					xs={2}
					sx={{
						backgroundColor: altColor1,
						minWidth: "65px",
						display: "flex",
						alignItems: "center",
						border: "1px solid #ccc", // Border around the input
						borderRadius: "4px", // Rounded corners
						padding: "5px 10px", // Padding inside the box
						"&:hover": {
							borderColor: "blue", // Border color on hover
						},
						"&:focus-within": {
							borderColor: "green", // Border color when focused
						},
					}}
				>
					<Stack spacing={0}>
						<Box>
							<Typography variant="body2">{day}</Typography>
							<Typography
								variant="body2"
								sx={{ color: colors.greenAccent[500] }}
							>
								{date}
							</Typography>
						</Box>
						<Box>
							<Button
								variant="text"
								size={"small"}
								sx={{
									color: colors.blueAccent[500],
									p: 0,
									m: 0,
									minWidth: 0,
								}}
								onClick={(e) => {
									const update = log.map(({ id, referenceDate, project }) => ({
										id: id,
										referenceDate: referenceDate,
										project: project,
										reg: 0,
										OT: 0,
										start: null,
										end: null,
									}));
									if (format === "weekday") {
										setSubtotal(0);
										setRegHours(0);
										setOTHours(0);
										setLog(update);
									} else if (format === "saturday") {
										setSubtotal(0);
										setSatHours(0);
										setLog(update);
									} else if (format === "sunday") {
										setSubtotal(0);
										setSunHours(0);
										setLog(update);
									}
								}}
							>
								Clear
							</Button>
						</Box>
					</Stack>
				</Grid>
				{/* Column 2 Start */}
				<Grid
					item
					xs={2.5}
					sx={{
						p: 1,
						my: 0.125,
						minWidth: "120px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						borderRadius: "4px", // Rounded corners
						padding: "5px 10px", // Padding inside the box
						"&:hover": {
							borderColor: "blue", // Border color on hover
							backgroundColor: colors.greenAccent[900],
						},
						"&:focus-within": {
							borderColor: "green", // Border color when focused
						},
					}}
				>
					<Stack>
						<CreateTimePickerComponent
							label="Start:"
							index={0}
							value={startSlot0}
							setvalue={setStartSlot0}
							log={log}
							setLog={setLog}
							format={format}
							referenceDate={date}
							day={day}
						/>
						<CreateTimePickerComponent
							label="End:"
							index={0}
							value={endSlot0}
							setvalue={setEndSlot0}
							log={log}
							setLog={setLog}
							format={format}
							referenceDate={date}
							day={day}
						/>
					</Stack>
				</Grid>
				<Grid item xs={2.5} sx={showRowColor1}>
					<Stack>
						<CreateTimePickerComponent
							label="Start:"
							index={1}
							value={startSlot1}
							setvalue={setStartSlot1}
							log={log}
							setLog={setLog}
							format={format}
							referenceDate={date}
							day={day}
						/>
						<CreateTimePickerComponent
							label="End:"
							index={1}
							value={endSlot1}
							setvalue={setEndSlot1}
							log={log}
							setLog={setLog}
							format={format}
							referenceDate={date}
							day={day}
						/>
					</Stack>
				</Grid>

				<Grid item xs={2.5} sx={showRowColor2}>
					<Stack>
						<CreateTimePickerComponent
							label="Start:"
							index={2}
							//value={log[2].start}
							value={startSlot2}
							setvalue={setStartSlot2}
							log={log}
							setLog={setLog}
							format={format}
							referenceDate={date}
							day={day}
						/>
						<CreateTimePickerComponent
							label="End:"
							index={2}
							//value={log[2].end}
							value={endSlot2}
							setvalue={setEndSlot2}
							log={log}
							setLog={setLog}
							format={format}
							referenceDate={date}
							day={day}
						/>
					</Stack>
				</Grid>

				<Grid
					item
					xs={1.5}
					sx={{
						backgroundColor: grey,
						minWidth: "50px",
						p: 1,
						my: 0.125,
						display: "flex",
						borderRadius: "4px", // Rounded corners
						padding: "5px 10px", // Padding inside the box
						"&:hover": {
							borderColor: "blue", // Border color on hover
							backgroundColor: colors.greenAccent[900],
						},
						"&:focus-within": {
							borderColor: "green", // Border color when focused
						},
					}}
				>
					{formatTypography()}
				</Grid>
			</LocalizationProvider>
		</Grid>
	);
};

export default PayrollCreateRow;
