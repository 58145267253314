import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { tokens } from '../theme';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography variant='body2'>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function styles(colors) {
  return {
    fontSize: '0.8rem', // Adjust font size
    textTransform: 'none', // Disable uppercase text
    padding: '12px 16px', // Padding around text
    color: colors.grey[300], // Default text color
    '&.Mui-selected': {
      color: colors.greenAccent[300], // Text color when selected
      fontWeight: 'bold', // Make selected text bold
    },
    '&:hover': {
      color: colors.grey[200], // Change text color on hover
    },
    '&:focus': {
      outline: 'none',
      color: colors.greenAccent[300], // Change color when focused
    },
  };
}

export default function BasicTabs({ value, setValue }) {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //themes
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
          sx={
            {
              // '& button': { borderRadius: 1 },
              // '& button:hover': { backgroundColor: colors.blueAccent[700] },
              // '& button:focus': { backgroundColor: colors.greenAccent[900] },
              // '& button:active': { backgroundColor: colors.greenAccent[900] },
            }
          }
          indicatorColor='primary'>
          <Tab label='1 Project' {...a11yProps(0)} sx={styles(colors)} />
          <Tab label='2 Projects' {...a11yProps(1)} sx={styles(colors)} />
          <Tab label='3 Projects' {...a11yProps(2)} sx={styles(colors)} />
        </Tabs>
      </Box>
    </Box>
  );
}
