import { useState, useEffect } from "react";
import DashBoardBox from "../components/DashBoardBox";
import AnalyticsBoxHeader from "../components/AnalyticsBoxHeader";
import { Box, Stack, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Alert from "@mui/material/Alert";
import {
	ResponsiveContainer,
	XAxis,
	YAxis,
	Tooltip,
	Bar,
	BarChart,
	Pie,
	PieChart,
	Cell,
	Legend,
	Sector,
} from "recharts";

import dayjs from "dayjs";
import { useAuth } from "../context/AuthProvider";
import { projectAnalytics } from "../utils/projectAnalytics";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import threeWeekLookBack from "../hooks/three-week-lookback";

const Row1 = ({ timecardData, error, unfilteredData, label }) => {
	const start = dayjs().week(label).day(0).format("MM/DD/YYYY");
	const end = dayjs().week(label).day(6).format("MM/DD/YYYY");
	const NewLabel = `Sun (${start}) - Sat (${end})`;
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [errMsg, setErrMsg] = useState(error);
	const [tcDataRendered, settcDataRendered] = useState([]);
	const [activeIndex, setactiveIndex] = useState({
		activeIndex: 0,
	});
	const { user, setUser } = useAuth();
	const [weekSelect, setWeekSelect] = useState("Past 6 Weeks");
	const weeksObject = threeWeekLookBack();
	const handleChange = (event) => {
		setWeekSelect(event.target.value);
	};

	useEffect(() => {
		//setAllTimecards(data);
		let renderedData = [];
		//map through array of objectsw
		if (timecardData) {
			renderedData = timecardData.map((timecard) => ({
				id: timecard.date,
				weekEnd: dayjs(timecard.date).format("MM-DD-YYYY"),
				regularHours: timecard.regHours,
				OTHours: timecard.OTHours,
				saturdayHours: timecard.saturdayHours,
				sundayHours: timecard.sundayHours,
			}));
		}

		settcDataRendered(renderedData.reverse());
	}, [timecardData, user]);

	const transactionColumns = [
		{
			field: "weekEnd",
			headerName: "Week Ending",
			flex: 0.4,
		},
		{
			field: "regularHours",
			headerName: "Reg",
			flex: 0.2,
			renderCell: (params) => `${params.value}`,
		},
		{
			field: "OTHours",
			headerName: "OT",
			flex: 0.2,
			renderCell: (params) => `${params.value}`,
		},
		{
			field: "saturdayHours",
			headerName: "Sat",
			flex: 0.2,
			renderCell: (params) => `${params.value}`,
		},
		{
			field: "sundayHours",
			headerName: "Sun",
			flex: 0.2,
			renderCell: (params) => `${params.value}`,
		},
	];

	const returnArray = projectAnalytics(unfilteredData);

	const COLORS = [
		colors.greenAccent[500],
		colors.redAccent[500],
		colors.blueAccent[500],
		colors.grey[500],
	];

	const renderActiveShape = (props) => {
		const RADIAN = Math.PI / 180;
		const {
			cx,
			cy,
			midAngle,
			innerRadius,
			outerRadius,
			startAngle,
			endAngle,
			fill,
			payload,
			percent,
			value,
		} = props;
		const sin = Math.sin(-RADIAN * midAngle);
		const cos = Math.cos(-RADIAN * midAngle);
		const sx = cx + (outerRadius + 10) * cos;
		const sy = cy + (outerRadius + 10) * sin;
		const mx = cx + (outerRadius + 30) * cos;
		const my = cy + (outerRadius + 30) * sin;
		const ex = mx + (cos >= 0 ? 1 : -1) * 22;
		const ey = my;
		const textAnchor = cos >= 0 ? "start" : "end";

		return (
			<g>
				<text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
					{payload.name}
				</text>
				<Sector
					cx={cx}
					cy={cy}
					innerRadius={innerRadius}
					outerRadius={outerRadius}
					startAngle={startAngle}
					endAngle={endAngle}
					fill={fill}
				/>
				<Sector
					cx={cx}
					cy={cy}
					startAngle={startAngle}
					endAngle={endAngle}
					innerRadius={outerRadius + 6}
					outerRadius={outerRadius + 10}
					fill={fill}
				/>
				<path
					d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
					stroke={fill}
					fill="none"
				/>
				<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
				<text
					x={ex + (cos >= 0 ? 1 : -1) * 12}
					y={ey}
					textAnchor={textAnchor}
					fill="#333"
				>{`PV ${value}`}</text>
				<text
					x={ex + (cos >= 0 ? 1 : -1) * 12}
					y={ey}
					dy={18}
					textAnchor={textAnchor}
					fill="#999"
				>
					{`(Rate ${(percent * 100).toFixed(2)}%)`}
				</text>
			</g>
		);
	};

	const onPieEnter = (index) => {
		setactiveIndex({
			activeIndex: index,
		});
	};

	return (
		<>
			<DashBoardBox gridArea="a" theme={theme} colors={colors}>
				<AnalyticsBoxHeader
					title={`${user.firstName} ${user.lastName}'s Timecards`}
					subtitle="Datagrid"
					sidetext="+0%"
				/>
				{errMsg ? <Alert severity="error">{errMsg}</Alert> : null}
				<Box
					mt="0.5rem"
					p="0 0.5rem"
					height="80%"
					sx={{
						"& .MuiDataGrid-root": {
							color: `${colors.grey[300]}`,
							border: "none",
						},
						"& .MuiDataGrid-cell": {
							borderBottom: `1px solid ${colors.grey[800]} !important`,
						},
						"& .MuiDataGrid-columnSeparator": {
							visibility: "hidden",
						},
					}}
				>
					<DataGrid
						rowHeight={25}
						columnHeaderHeight={25}
						hideFooter={true}
						rows={tcDataRendered || []}
						columns={transactionColumns}
					/>
				</Box>
			</DashBoardBox>
			<DashBoardBox gridArea="b" theme={theme} colors={colors}>
				<Stack direction="row" spacing={2} alignItems="right">
					<AnalyticsBoxHeader
						title={`${user.firstName} ${user.lastName}'s Timecards`}
						subtitle="Last 6 Weeks"
					/>
					<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
						<Select
							labelId="demo-select-small-label"
							id="demo-select-small"
							value={weekSelect}
							onChange={handleChange}
						>
							<MenuItem value="Past 6 Weeks">
								<em>Past 6 Weeks</em>
							</MenuItem>
							{weeksObject?.sixWkBkDesc?.map((project) => (
								<MenuItem key={project} value={project}>
									{project}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Stack>
				{errMsg ? <Alert severity="error">{errMsg}</Alert> : null}
				<ResponsiveContainer width="100%" height="70%">
					<BarChart
						width={500}
						height={300}
						data={timecardData}
						margin={{
							top: 20,
							right: 30,
							left: 20,
							bottom: 5,
						}}
					>
						<defs>
							<linearGradient id="colorHours" x1="0" y1="0" x2="0" y2="1">
								<stop
									offset="5%"
									stopColor={colors.greenAccent[400]}
									stopOpacity={0.5}
								/>
								<stop
									offset="95%"
									stopColor={colors.greenAccent[600]}
									stopOpacity={0.3}
								/>
							</linearGradient>
							<linearGradient id="colorExpenses" x1="0" y1="0" x2="0" y2="1">
								<stop
									offset="25%"
									stopColor={colors.redAccent[600]}
									stopOpacity={0.5}
								/>
								<stop
									offset="95%"
									stopColor={colors.redAccent[800]}
									stopOpacity={0.3}
								/>
							</linearGradient>
						</defs>
						<XAxis dataKey="date" />
						<YAxis />
						<Tooltip />
						<Legend />
						<Bar
							dataKey="regHours"
							stackId="a"
							fill="url(#colorHours)"
							fillOpacity={0.9}
						/>
						<Bar dataKey="OTHours" stackId="a" fill="#82ca9d" />
						<Bar
							dataKey="saturdayHours"
							stackId="a"
							fill="hsl(106, 70%, 50%)"
						/>
						<Bar dataKey="sundayHours" stackId="a" fill="hsl(256, 70%, 50%)" />
					</BarChart>
				</ResponsiveContainer>
			</DashBoardBox>
			<DashBoardBox gridArea="c" theme={theme} colors={colors}>
				<AnalyticsBoxHeader
					title="Weekly Project Breakdown"
					subtitle="Project Analysis"
					sidetext={NewLabel}
				/>

				{errMsg ? <Alert severity="error">{errMsg}</Alert> : null}
				<Box
					mt="0.5rem"
					p="0 0.5rem"
					height="75%"
					sx={{
						"& .MuiDataGrid-root": {
							color: `${colors.grey[300]}`,
							border: "none",
						},
						"& .MuiDataGrid-cell": {
							borderBottom: `1px solid ${colors.grey[800]} !important`,
						},
						"& .MuiDataGrid-columnSeparator": {
							visibility: "hidden",
						},
					}}
				>
					<ResponsiveContainer width="100%" height="100%">
						<PieChart>
							<Pie
								activeIndex={activeIndex}
								activeShape={renderActiveShape}
								data={returnArray}
								cx="50%"
								cy="50%"
								innerRadius={50}
								outerRadius={70}
								label
								stroke="none"
								sx={{ border: "black" }}
								dataKey="totalHours"
							>
								{returnArray.map((entry, index) => (
									<Cell
										name={entry.project}
										key={`cell-${entry} ${index}`}
										fill={COLORS[index % COLORS.length]}
										sx={{ border: "none" }}
									/>
								))}
							</Pie>
							<Tooltip />
						</PieChart>
					</ResponsiveContainer>
				</Box>
			</DashBoardBox>
		</>
	);
};

export default Row1;
