//sample test page

import { useState, useEffect } from "react";
//import TopBarAdmin from '../components/TopBarAdmin';
//import { Box, Grid } from '@mui/material';
import { Typography } from "@mui/joy";
import Button from "@mui/joy/Button";
import Box from "@mui/joy/Box";
import Grid from "@mui/joy/Grid";
import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Sidebar from "../joy/components/Sidebar";
import Header from "../joy/components/Header";
import MyProfile from "../joy/components/MyProfile";

function TestGroundsPage({ location }) {
	return (
		<CssVarsProvider disableTransitionOnChange>
			<CssBaseline />

			<Box sx={{ display: "flex", minHeight: "100dvh" }}>
				<Sidebar location={location} />
				<Header />
				<Box
					component="main"
					className="MainContent"
					sx={{
						pt: { xs: "calc(12px + var(--Header-height))", md: 3 },
						pb: { xs: 2, sm: 2, md: 3 },
						flex: 1,
						display: "flex",
						flexDirection: "column",
						minWidth: 0,
						height: "100dvh",
						gap: 1,
						overflow: "auto",
					}}
				>
					<MyProfile />
				</Box>
			</Box>
		</CssVarsProvider>
	);
}

export default TestGroundsPage;
