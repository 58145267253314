import { useState, useEffect, useMemo } from "react";
import { Box, Grid, Typography, useTheme, Button, Stack } from "@mui/material";
import TopBarAdmin from "../components/TopBarAdmin";
import PayrollCreateRow from "../components/PayrollCreateRow";
import useFetch from "../hooks/fetchAPI";
import usePath from "../hooks/fetchPath";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import threeWeekLookBack from "../hooks/three-week-lookback";
import { tokens } from "../theme";
import BasicTabs from "../components/Tabs";
import SendIcon from "@mui/icons-material/Send";
import dayjs from "dayjs";
import FormHelperText from "@mui/material/FormHelperText";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Link, Navigate } from "react-router-dom";
import AddPersonnelModal from "../components/AddPersonnelModal";
import EditPersonnelModal from "../components/EditPersonnelModal";
import LinearProgress from "@mui/material/LinearProgress";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";

//utils
import { CreateStandardWorkWeek } from "../utils/createObjectsInArray";
import { compilePayrollCreateDetails } from "../utils/compilePayollCreateDetails";
import { useAuth } from "../context/AuthProvider";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { CreateCustomWorkWeek } from "./../utils/createObjectsInArray";
import useMediaQuery from "@mui/material/useMediaQuery";

function PayrollCreatePage({ user, auth }) {
	const { api } = useAuth();
	const { baseURL } = usePath("/api/v1/projects/list");

	//themes
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const { threeWkBk } = threeWeekLookBack();
	const [weekSelect, setWeekSelect] = useState(threeWkBk[1]);
	const [loadingHours, setloadingHours] = useState(true);
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	//load all user data
	//const { data, loading, error } = useFetch("/api/v1/projects/list");
	const [errState, seterrState] = useState(false);
	const [errMsg, seterrMsg] = useState("");
	const [project1, setProject1] = useState("");
	const [project2, setProject2] = useState("");
	const [project3, setProject3] = useState("");
	const [project1Error, setproject1Error] = useState(true);
	const [project2Error, setproject2Error] = useState(true);
	const [project3Error, setproject3Error] = useState(true);
	const [consoleError, setconsoleError] = useState(false);
	const [sentPayroll, setsentPayroll] = useState(false);
	var customParseFormat = require("dayjs/plugin/customParseFormat");
	dayjs.extend(customParseFormat);
	var weekday = require("dayjs/plugin/weekday");
	dayjs.extend(weekday);
	const [sundayDate, setsundayDate] = useState(
		dayjs(weekSelect, "MM/DD/YYYY").weekday(0).format("MM/DD/YYYY")
	);
	const [mondayDate, setmondayDate] = useState(
		dayjs(weekSelect, "MM/DD/YYYY").weekday(1).format("MM/DD/YYYY")
	);
	const [tuesdayDate, settuesdayDate] = useState(
		dayjs(weekSelect, "MM/DD/YYYY").weekday(2).format("MM/DD/YYYY")
	);
	const [wednesdayDate, setwednesdayDate] = useState(
		dayjs(weekSelect, "MM/DD/YYYY").weekday(3).format("MM/DD/YYYY")
	);
	const [thursdayDate, setthursdayDate] = useState(
		dayjs(weekSelect, "MM/DD/YYYY").weekday(4).format("MM/DD/YYYY")
	);
	const [fridayDate, setfridayDate] = useState(
		dayjs(weekSelect, "MM/DD/YYYY").weekday(5).format("MM/DD/YYYY")
	);
	const [saturdayDate, setsaturdayDate] = useState(
		dayjs(weekSelect, "MM/DD/YYYY").weekday(6).format("MM/DD/YYYY")
	);
	//project tab state
	const [personnelList, setPersonnelList] = useState();
	const [projectTab, setProjectTab] = useState(0);
	const [showProject2, setShowProject2] = useState({ display: "none" });
	const [showProject3, setShowProject3] = useState({ display: "none" });

	const [showRowColor1, setShowRowColor1] = useState({ display: "none" });
	const [showRowColor2, setShowRowColor2] = useState(null);
	const [showBottomRow1, setShowBottomRow1] = useState({ display: "none" });
	const [showBottomRow2, setShowBottomRow2] = useState({ display: "none" });

	//radio group
	const [radioSelection, setRadioSelection] = useState("saved");

	const handleRadioChange = (event) => {
		setRadioSelection(event.target.value);
	};

	//create hours subtotal states
	const [sundayHours, setsundayHours] = useState(0);
	const [mondayRegHours, setmondayRegHours] = useState(8);

	const [mondayOTHours, setmondayOTHours] = useState(0);
	const [tuesdayRegHours, settuesdayRegHours] = useState(8);
	const [tuesdayOTHours, settuesdayOTHours] = useState(0);
	const [wednesdayRegHours, setwednesdayRegHours] = useState(8);
	const [wednesdayOTHours, setwednesdayOTHours] = useState(0);
	const [thursdayRegHours, setthursdayRegHours] = useState(8);
	const [thursdayOTHours, setthursdayOTHours] = useState(0);
	const [fridayRegHours, setfridayRegHours] = useState(8);
	const [fridayOTHours, setfridayOTHours] = useState(0);
	const [saturdayHours, setsaturdayHours] = useState(0);
	const [details, setDetails] = useState([]);
	const [peopleDropdown, setPeopleDropdown] = useState("");
	const [person, setPerson] = useState("");
	const [employeeID, setemployeeID] = useState(user.employeeID);
	const [trade, setTrade] = useState(user.role);
	const [classification, setClassification] = useState(user.classification);
	//modal
	//modal
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const handleEditModalOpen = () => setEditModalOpen(true);
	const handleEditModalClose = () => setEditModalOpen(false);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [role, setRole] = useState("Carpenter");
	const [notificationsNumber, setnotificationsNumber] = useState(0);
	const [editModalPerson, setEditModalPerson] = useState({});
	const [projectList, setProjectList] = useState([]);
	//Create Standard Work Days
	const [sundayLog, setsundayLog] = useState(
		CreateStandardWorkWeek(3, "sun", weekSelect)
	);
	const [mondayLog, setmondayLog] = useState(
		CreateStandardWorkWeek(3, "mon", weekSelect)
	);

	const [tuesdayLog, settuesdayLog] = useState(
		CreateStandardWorkWeek(3, "tue", weekSelect)
	);
	const [wednesdayLog, setwednesdayLog] = useState(
		CreateStandardWorkWeek(3, "wed", weekSelect)
	);
	const [thursdayLog, setthursdayLog] = useState(
		CreateStandardWorkWeek(3, "thur", weekSelect)
	);
	const [fridayLog, setfridayLog] = useState(
		CreateStandardWorkWeek(3, "fri", weekSelect)
	);
	const [saturdayLog, setsaturdayLog] = useState(
		CreateStandardWorkWeek(3, "sat", weekSelect)
	);

	console.log(details);
	//Set Week
	useEffect(() => {
		const dayofweek = dayjs().day();
		if (
			dayofweek === 0 ||
			dayofweek === 1 ||
			dayofweek === 2 ||
			dayofweek === 3 ||
			dayofweek === 4
		) {
			const seed = threeWkBk[1];
			setWeekSelect(seed);
			const sunday = dayjs(seed, "MM/DD/YYYY").weekday(0);
			const monday = dayjs(seed, "MM/DD/YYYY").weekday(1);
			const tuesday = dayjs(seed, "MM/DD/YYYY").weekday(2);
			const wednesday = dayjs(seed, "MM/DD/YYYY").weekday(3);
			const thursday = dayjs(seed, "MM/DD/YYYY").weekday(4);
			const friday = dayjs(seed, "MM/DD/YYYY").weekday(5);
			const saturday = dayjs(seed, "MM/DD/YYYY").weekday(6);
			setsundayDate(sunday.format("MM/DD/YYYY"));
			setmondayDate(monday.format("MM/DD/YYYY"));
			settuesdayDate(tuesday.format("MM/DD/YYYY"));
			setwednesdayDate(wednesday.format("MM/DD/YYYY"));
			setthursdayDate(thursday.format("MM/DD/YYYY"));
			setfridayDate(friday.format("MM/DD/YYYY"));
			setsaturdayDate(saturday.format("MM/DD/YYYY"));
			//change all reference
			setsundayDate(sunday.format("MM/DD/YYYY"));
		} else if (dayofweek === 5 || dayofweek === 6) {
			const seed = threeWkBk[0];
			setWeekSelect(seed);
			const sunday = dayjs(seed, "MM/DD/YYYY").weekday(0);
			const monday = dayjs(seed, "MM/DD/YYYY").weekday(1);
			const tuesday = dayjs(seed, "MM/DD/YYYY").weekday(2);
			const wednesday = dayjs(seed, "MM/DD/YYYY").weekday(3);
			const thursday = dayjs(seed, "MM/DD/YYYY").weekday(4);
			const friday = dayjs(seed, "MM/DD/YYYY").weekday(5);
			const saturday = dayjs(seed, "MM/DD/YYYY").weekday(6);
			setsundayDate(sunday.format("MM/DD/YYYY"));
			setmondayDate(monday.format("MM/DD/YYYY"));
			settuesdayDate(tuesday.format("MM/DD/YYYY"));
			setwednesdayDate(wednesday.format("MM/DD/YYYY"));
			setthursdayDate(thursday.format("MM/DD/YYYY"));
			setfridayDate(friday.format("MM/DD/YYYY"));
			setsaturdayDate(saturday.format("MM/DD/YYYY"));
		}
	}, []);

	//Loading Data
	useEffect(() => {
		async function GetResults(data) {
			try {
				const response = await api.get(
					`/api/v1/timecardsv2/unapproved/?page=1&limit=1000`
				);

				setnotificationsNumber(response.data.data[0].results.length);
			} catch (err) {
				console.log("Failed", { err });
			}
		}
		GetResults();
	}, [api]);

	useMemo(() => {
		const hiddenStyles = {
			display: "none",
			minWidth: "120px",
		};

		if (projectTab === 0) {
			setShowProject2(hiddenStyles);
			setShowProject3(hiddenStyles);

			setShowRowColor1({ display: "none" });
			setShowRowColor2({ display: "none", minWidth: "120px" });
			setShowBottomRow1({ display: "none", minWidth: "120px" });
			setShowBottomRow2({ display: "none", minWidth: "120px" });
		} else if (projectTab === 1) {
			setShowProject2({
				color: colors.grey[300],
				backgroundColor: colors.primary[600],
				minWidth: "120px",
			});
			setShowProject3(hiddenStyles);

			setShowRowColor1({
				backgroundColor: colors.blueAccent[700],
				minWidth: "120px",
				p: 1,
				my: 0.125,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				borderRadius: "4px", // Rounded corners
				padding: "5px 10px", // Padding inside the box
				"&:hover": {
					borderColor: "blue", // Border color on hover
					backgroundColor: colors.greenAccent[900],
				},
				"&:focus-within": {
					borderColor: "green", // Border color when focused
				},
			});
			setShowRowColor2({ display: "none", minWidth: "120px" });
			setShowBottomRow1({
				backgroundColor: colors.grey[600],
				minWidth: "120px",
				p: 1,
				my: 0.125,
				display: "flex",
				borderRadius: "4px", // Rounded corners
				padding: "5px 10px", // Padding inside the box
				"&:hover": {
					borderColor: "blue", // Border color on hover
					backgroundColor: colors.greenAccent[900],
				},
				"&:focus-within": {
					borderColor: "green", // Border color when focused
				},
			});
			setShowBottomRow2({ display: "none", minWidth: "120px" });
		} else if (projectTab === 2) {
			setShowProject2({
				color: colors.grey[300],
				backgroundColor: colors.primary[400],
				minWidth: "120px",
				p: 1,
				my: 0.125,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				borderRadius: "4px", // Rounded corners
				padding: "5px 10px", // Padding inside the box
				"&:hover": {
					borderColor: "blue", // Border color on hover
					backgroundColor: colors.greenAccent[900],
				},
				"&:focus-within": {
					borderColor: "green", // Border color when focused
				},
			});
			setShowProject3({
				color: colors.grey[300],
				backgroundColor: colors.primary[500],
				minWidth: "120px",
				p: 1,
				my: 0.125,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				borderRadius: "4px", // Rounded corners
				padding: "5px 10px", // Padding inside the box
				"&:hover": {
					borderColor: "blue", // Border color on hover
					backgroundColor: colors.greenAccent[900],
				},
				"&:focus-within": {
					borderColor: "green", // Border color when focused
				},
			});

			setShowRowColor1({
				backgroundColor: colors.blueAccent[800],
				minWidth: "120px",
				p: 1,
				my: 0.125,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				borderRadius: "4px", // Rounded corners
				padding: "5px 10px", // Padding inside the box
				"&:hover": {
					borderColor: "blue", // Border color on hover
					backgroundColor: colors.greenAccent[900],
				},
				"&:focus-within": {
					borderColor: "green", // Border color when focused
				},
			});
			setShowRowColor2({
				backgroundColor: colors.blueAccent[900],
				minWidth: "120px",
				p: 1,
				my: 0.125,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				borderRadius: "4px", // Rounded corners
				padding: "5px 10px", // Padding inside the box
				"&:hover": {
					borderColor: "blue", // Border color on hover
					backgroundColor: colors.greenAccent[900],
				},
				"&:focus-within": {
					borderColor: "green", // Border color when focused
				},
			});
			setShowBottomRow1({
				backgroundColor: colors.grey[800],
				minWidth: "120px",
				p: 1,
				my: 0.125,
				display: "flex",
				borderRadius: "4px", // Rounded corners
				padding: "5px 10px", // Padding inside the box
				"&:hover": {
					borderColor: "blue", // Border color on hover
					backgroundColor: colors.greenAccent[900],
				},
				"&:focus-within": {
					borderColor: "green", // Border color when focused
				},
			});
			setShowBottomRow2({
				backgroundColor: colors.grey[900],
				minWidth: "120px",
				p: 1,
				my: 0.125,
				display: "flex",
				borderRadius: "4px", // Rounded corners
				padding: "5px 10px", // Padding inside the box
				"&:hover": {
					borderColor: "blue", // Border color on hover
					backgroundColor: colors.greenAccent[900],
				},
				"&:focus-within": {
					borderColor: "green", // Border color when focused
				},
			});
		}
	}, [projectTab]);

	useEffect(() => {
		api
			.get(`/api/v1/projects/list`)
			.then((response) => {
				console.log(response);
				setProjectList(response.data.data);
				seterrState(false);
				seterrMsg("");
			})
			.catch((err) => {
				console.log("Failed", { err });
				seterrState(true);
				seterrMsg("Error Loading project list data");
			});
	}, [api]);

	//loads personnel list
	//searches for current user in personnel list
	useEffect(() => {
		const findFunction = async function (array) {
			if (Object.keys(editModalPerson).length === 0) {
				const found = array.find(
					(elem) => elem.fullName === user.lastName + ", " + user.firstName
				);

				if (found) {
					setEditModalPerson(found);
					const sunday = CreateCustomWorkWeek(
						"sun",
						weekSelect,
						found.sunStart,
						found.sunEnd
					);
					const monday = CreateCustomWorkWeek(
						"mon",
						weekSelect,
						found.monStart,
						found.monEnd
					);
					const tuesday = CreateCustomWorkWeek(
						"tue",
						weekSelect,
						found.tueStart,
						found.tueEnd
					);
					const wednesday = CreateCustomWorkWeek(
						"wed",
						weekSelect,
						found.wedStart,
						found.wedEnd
					);
					const thursday = CreateCustomWorkWeek(
						"thur",
						weekSelect,
						found.thurStart,
						found.thurEnd
					);
					const friday = CreateCustomWorkWeek(
						"fri",
						weekSelect,
						found.friStart,
						found.friEnd
					);
					const saturday = CreateCustomWorkWeek(
						"sat",
						weekSelect,
						found.satStart,
						found.satEnd
					);
					setsundayHours(sunday[0].reg ? sunday[0].reg : 0);
					setmondayRegHours(monday[0].reg ? monday[0].reg : 0);
					setmondayOTHours(monday[0].OT ? monday[0].OT : 0);
					settuesdayRegHours(tuesday[0].reg);
					settuesdayOTHours(tuesday[0].OT ? tuesday[0].OT : 0);
					setwednesdayRegHours(wednesday[0].reg);
					setwednesdayOTHours(wednesday[0].OT ? wednesday[0].OT : 0);
					setthursdayRegHours(thursday[0].reg);
					setthursdayOTHours(thursday[0].OT ? thursday[0].OT : 0);
					setfridayRegHours(friday[0].reg);
					setfridayOTHours(friday[0].OT ? friday[0].OT : 0);
					setsaturdayHours(saturday[0].reg ? saturday[0].reg : 0);
					setsundayLog(sunday);

					setmondayLog(
						CreateCustomWorkWeek(
							"mon",
							weekSelect,
							found.monStart,
							found.monEnd
						)
					);
					settuesdayLog(
						CreateCustomWorkWeek(
							"tue",
							weekSelect,
							found.tueStart,
							found.tueEnd
						)
					);
					setwednesdayLog(
						CreateCustomWorkWeek(
							"wed",
							weekSelect,
							found.wedStart,
							found.wedEnd
						)
					);
					setthursdayLog(
						CreateCustomWorkWeek(
							"thur",
							weekSelect,
							found.thurStart,
							found.thurEnd
						)
					);
					setfridayLog(
						CreateCustomWorkWeek(
							"fri",
							weekSelect,
							found.friStart,
							found.friEnd
						)
					);
					setsaturdayLog(
						CreateCustomWorkWeek(
							"sat",
							weekSelect,
							found.satStart,
							found.satEnd
						)
					);
				}

				return found;
			}
		};

		if (api.authenticated) {
			api
				.get(`/api/v1/personnel?sort=fullName&limit=1000`)
				.then((response) => {
					setPersonnelList(response.data.data);
					findFunction(response.data.data);
				})
				.catch((err) => {
					console.log("FAILED", { err });
				});
		}
	}, [
		baseURL,
		api.authenticated,
		api,
		user.lastName,
		user.firstName,
		editModalPerson,
		weekSelect,
	]);

	useEffect(() => {
		setPeopleDropdown(user.lastName + ", " + user.firstName);
	}, [user.firstName, user.lastName, user.role]);

	useEffect(() => {
		setDetails(
			compilePayrollCreateDetails(
				user,
				person,
				weekSelect,
				employeeID,
				project1,
				project2,
				project3,
				sundayLog,
				mondayLog,
				tuesdayLog,
				wednesdayLog,
				thursdayLog,
				fridayLog,
				saturdayLog,
				sundayHours,
				mondayRegHours,
				mondayOTHours,
				tuesdayRegHours,
				tuesdayOTHours,
				wednesdayRegHours,
				wednesdayOTHours,
				thursdayRegHours,
				thursdayOTHours,
				fridayRegHours,
				fridayOTHours,
				saturdayHours,
				trade,
				classification
			)
		);
		setloadingHours(false);
	}, [
		classification,
		employeeID,
		fridayLog,
		fridayOTHours,
		fridayRegHours,
		mondayLog,
		mondayOTHours,
		mondayRegHours,
		person,
		project1,
		project2,
		project3,
		saturdayHours,
		saturdayLog,
		sundayHours,
		sundayLog,
		thursdayLog,
		thursdayOTHours,
		thursdayRegHours,
		trade,
		tuesdayLog,
		tuesdayOTHours,
		tuesdayRegHours,
		user,
		wednesdayLog,
		wednesdayOTHours,
		wednesdayRegHours,
		weekSelect,
	]);

	//when people dropdown changes, change classification and role
	const handlePeopleChange = (e) => {
		const newName = e.target.value;
		setPeopleDropdown(newName);
		setPerson(newName);
		setEditModalPerson(newName);

		const findFunction = async function (array) {
			const found = await array.find((elem) => elem.fullName === newName);
			return found;
		};

		findFunction(personnelList).then((found) => {
			setemployeeID(found.employeeID);
			setTrade(found.role);
			setClassification(found.classification);
			setEditModalPerson(found);
			//use split function
			//   const names = found.fullName.split(',');
			//   const firstName = names[1].trim();
			//   const lastName = names[0].trim();
			//   const name = firstName + '_' + lastName + '_' + weekSelect;

			//   const final = {
			//     ...details,
			//     name: name,
			//     classification: found.classification,
			//     trade: found.role,
			//     employeeID: found.employeeID,
			//     fullName: found.fullName,
			//     firstName: firstName,
			//     lastName: lastName,
			//   };
			// console.log(final);
			//setPerson(found.fullName);
			//setDetails(final);
		});
	};

	// Payroll Create Stuff
	const submitPayroll = async (details) => {
		api
			.post(baseURL + "/api/v1/timecardsv2", JSON.stringify(details))
			.then((response) => {
				setsentPayroll(true);
			})
			.catch((error) => {
				setconsoleError(true);
				if (error.response.data.error === "Duplicate field value entered") {
					seterrMsg(
						"Only 1 Timecard Per Week Per User (Duplicate Field Entered)"
					);
				} else {
					seterrMsg(error.message);
				}
			});
	};

	const checkErrors = () => {
		seterrState(false);
		let error;
		if (project1 === "" && projectTab === 0) {
			seterrState(true);
			seterrMsg("Please select a project ");
			error = true;
		} else if (
			(project2 === "" && projectTab === 1) ||
			(project1 === "" && projectTab === 1)
		) {
			seterrState(true);
			seterrMsg("Please select a project ");
			error = true;
		} else if (
			(project3 === "" || project2 === "" || project1 === "") &&
			projectTab === 2
		) {
			seterrState(true);
			seterrMsg("Please select a project ");
			error = true;
		}
		return error;
	};

	//correct logs
	const checkTimeStartErrors = (log) => {
		seterrState(false);
		let error = false;
		let id;

		for (let i = 0; i < log.length; i++) {
			let start;
			let end;
			if (log[i].start && log[i].end) {
				start = dayjs(log[i].start);

				end = dayjs(log[i].end);

				if (start.diff(end) > 0 || start.diff(end) === 0) {
					seterrState(true);
					seterrMsg("Check Start and End Time at " + log[i].id);
					error = true;
					id = log[i].id;

					break;
				}
			}
		}
		return { error, id };
	};

	//correct logs
	const checkAllErrors = (array) => {
		seterrState(false);
		let error = false;
		let id;
		for (let i = 0; i < array.length; i++) {
			const obj = checkTimeStartErrors(array[i]);

			if (obj.error === true) {
				error = true;
				id = obj.id;

				break;
			}
		}
		return { error, id };
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		const error = checkErrors();
		const startAndStopErr = checkAllErrors([
			mondayLog,
			tuesdayLog,
			wednesdayLog,
			thursdayLog,
			fridayLog,
			saturdayLog,
		]);

		console.log(startAndStopErr, "start and stop error");
		if (error) {
			console.log("error");
			seterrState(true);
			seterrMsg("Please correct blank projects before submitting");
		} else if (startAndStopErr.error === true) {
			console.log("Start and Stop Error");
			seterrState(true);
			seterrMsg(
				`Please correct ${startAndStopErr.id} start and end times before submitting`
			);
		}

		if (!error && startAndStopErr.error === false) {
			submitPayroll(details);
		}
	};

	//Create Radio Group
	const CreateRadioGroup = ({ value, handleChange }) => {
		return (
			<Box width="100%">
				<FormControl>
					<FormLabel id="demo-row-radio-buttons-group-label">
						<Typography variant="body2" color="textSecondary">
							Timecard Start and Stop Times
						</Typography>
					</FormLabel>
					<RadioGroup
						row
						aria-labelledby="demo-row-radio-buttons-group-label"
						name="row-radio-buttons-group"
						value={value}
						onChange={handleChange}
					>
						<FormControlLabel
							value="prev"
							disabled={true}
							control={<Radio />}
							label={
								<Typography variant="body2" color="textSecondary">
									Prev
								</Typography>
							}
						/>
						<FormControlLabel
							value="saved"
							control={<Radio size="small" />}
							label={
								<Typography variant="body2" color="textSecondary">
									Saved
								</Typography>
							}
						/>
					</RadioGroup>
				</FormControl>
			</Box>
		);
	};

	//Create dropdown function for time pickers
	const CreateProjectDropDownComponent = ({
		error,
		data,
		state,
		label,
		callBack,
		id,
	}) => {
		return (
			<FormControl
				variant="standard"
				error={error}
				sx={{
					width: isSmallScreen ? "95px" : "80%",
					minWidth: isSmallScreen ? "95px" : "80%",

					"& .MuiInputLabel-root": {
						color: "grey",
						fontSize: "0.75rem",
					},
					"& .MuiSelect-select": {
						fontSize: "0.75rem", // Adjust font size for the select input
					},
				}}
			>
				<InputLabel id="demo-simple-select-error-label">{label}</InputLabel>
				<Select
					labelId="demo-simple-select-standard-label"
					id="demo-simple-select-standard"
					value={state}
					sx={{
						fontSize: "0.75rem",
						width: "100%",
					}}
					onChange={(prev) => {
						const newValue = prev.target.value;
						callBack(newValue);

						if (id === "project1") {
							sundayLog[0].project = prev.target.value;
							mondayLog[0].project = prev.target.value;
							tuesdayLog[0].project = prev.target.value;
							wednesdayLog[0].project = prev.target.value;
							thursdayLog[0].project = prev.target.value;
							fridayLog[0].project = prev.target.value;
							saturdayLog[0].project = prev.target.value;
						} else if (id === "project2") {
							sundayLog[1].project = prev.target.value;
							mondayLog[1].project = prev.target.value;
							tuesdayLog[1].project = prev.target.value;
							wednesdayLog[1].project = prev.target.value;
							thursdayLog[1].project = prev.target.value;
							fridayLog[1].project = prev.target.value;
							saturdayLog[1].project = prev.target.value;
						} else if (id === "project3") {
							sundayLog[2].project = prev.target.value;
							mondayLog[2].project = prev.target.value;
							tuesdayLog[2].project = prev.target.value;
							wednesdayLog[2].project = prev.target.value;
							thursdayLog[2].project = prev.target.value;
							fridayLog[2].project = prev.target.value;
							saturdayLog[2].project = prev.target.value;
						}

						if (newValue && id === "project1") {
							setproject1Error(false);
						} else if (newValue === "" && id === "project1") {
							setproject1Error(true);
						} else if (newValue && id === "project2") {
							setproject2Error(false);
						} else if (newValue === "" && id === "project1") {
							setproject2Error(true);
						} else if (newValue && id === "project3") {
							setproject3Error(false);
						} else if (newValue === "" && id === "project3") {
							setproject3Error(true);
						}
					}}
					label="Start"
				>
					{data &&
						data.map((value, index) => {
							return (
								<MenuItem
									key={index}
									value={value}
									sx={{ fontSize: "5px", padding: "5px 10px" }}
								>
									{value}
								</MenuItem>
							);
						})}
				</Select>
				<FormHelperText>
					{project1Error && id === "project1" ? "Please enter a project" : null}
					{project2Error && id === "project2" ? "Please enter a project" : null}
					{project3Error && id === "project3" ? "Please enter a project" : null}
				</FormHelperText>
			</FormControl>
		);
	};

	useEffect(() => {}, [personnelList, user.firstName, user.lastName]);

	const CreateWeekEndDropDown = ({ input, state, label, onChange }) => {
		return (
			<FormControl
				variant="standard"
				size="small"
				sx={{
					width: isSmallScreen ? "55px" : "80%",
					minWidth: "50px",
					"& .MuiInputLabel-root": {
						color: "grey",
						fontSize: "0.75rem",
					},
					"& .MuiSelect-select": {
						fontSize: "0.75rem", // Adjust font size for the select input
					},
				}}
			>
				<InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
				<Select
					labelId="demo-simple-select-standard-label"
					id="demo-simple-select-standard"
					value={state}
					onChange={onChange}
					label="Start"
				>
					{input.map((value, index) => {
						return (
							<MenuItem key={index} value={value}>
								{value}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
		);
	};

	const CreatePeopleDropDown = ({ input, state, label, onChange }) => {
		return (
			<FormControl
				variant="standard"
				sx={{
					width: isSmallScreen ? "40px" : "80%",
					minWidth: "40px",

					"& .MuiInputLabel-root": {
						color: "grey",
						fontSize: "0.75rem",
					},
					"& .MuiSelect-select": {
						fontSize: "0.75rem", // Adjust font size for the select input
					},
				}}
			>
				<InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>

				<Select
					labelId="demo-simple-select-standard-label"
					id="demo-simple-select-standard"
					value={state}
					onChange={(e) => handlePeopleChange(e)}
					label="Start"
				>
					{input.map((value, index) => {
						return (
							<MenuItem
								key={index}
								value={value.fullName}
								name={value.employeeID}
							>
								{value.fullName}
							</MenuItem>
						);
					})}
					<MenuItem value="">Add New Personnel</MenuItem>
				</Select>

				<Stack direction="row" spacing={0.5} sx={{ pt: 1 }}>
					<Link component="button" variant="body2" onClick={handleOpen}>
						<AddBoxIcon
							sx={{
								color: colors.grey[200], // Default link color
								fontSize: isSmallScreen ? "1rem" : "1.5rem",
								textDecoration: "none", // Remove underline by default
								"&:hover": {
									color: colors.greenAccent[500], // Change color on hover
									textDecoration: "underline", // Underline on hover
									transition: "0.3s", // Smooth transition
								},
								"&:focus": {
									color: "red", // Focused state color
								},
							}}
						/>
					</Link>
					<Link
						component="button"
						variant="body2"
						onClick={handleEditModalOpen}
					>
						<DisplaySettingsIcon
							sx={{
								color: colors.grey[200], // Default link color
								fontSize: isSmallScreen ? "1rem" : "1.5rem",
								textDecoration: "none", // Remove underline by default
								"&:hover": {
									color: colors.greenAccent[500], // Change color on hover
									textDecoration: "underline", // Underline on hover
									transition: "0.3s", // Smooth transition
								},
								"&:focus": {
									color: "red", // Focused state color
								},
							}}
						/>
					</Link>
				</Stack>
			</FormControl>
		);
	};

	const updateLogsReferenceDate = (prevdaylog, dayjsObj, weekEnd, index) => {
		//provide new reference date
		const weekEndFormatted = dayjs(weekEnd, "MM/DD/YYYY").format("YYYYMMDD");
		const defaultStart = dayjs(weekEndFormatted + "T7:00").day(index);
		const defaultEnd = dayjs(weekEndFormatted + "T15:30").day(index);
		//loop through each array changing the reference date property

		let newDayLog = [...prevdaylog];
		//if 1 project

		newDayLog.map((elem) => {
			if (
				elem.id === "mon1" ||
				elem.id === "tue1" ||
				elem.id === "wed1" ||
				elem.id === "thur1" ||
				elem.id === "fri1"
			) {
				const startHour = elem.start ? dayjs(elem.start).hour() : "7:00";
				const endHour = elem.end ? dayjs(elem.end).hour() : "15:30";
				elem.referenceDate = dayjsObj;
				elem.start = dayjs(weekEndFormatted + "T" + startHour).day(index);
				elem.end = dayjs(weekEndFormatted + "T" + endHour).day(index);
			} else {
				elem.referenceDate = dayjsObj;
			}
			return elem;
		});
		return newDayLog;
	};

	return (
		<Box>
			{sentPayroll ? (
				<Navigate replace to="/UserPageTimecards" />
			) : (
				<Box sx={{ width: "95%", pb: 3 }}>
					<form onSubmit={handleSubmit}>
						<Link to="/DashBoard" />

						<TopBarAdmin notificationsNumber={notificationsNumber} />
						{errState ? (
							<Alert severity="error">
								<AlertTitle>Error</AlertTitle>
								<strong>{errMsg}!</strong>
							</Alert>
						) : null}

						{consoleError ? (
							<Alert severity="error">
								<AlertTitle>Error</AlertTitle>
								<strong>{errMsg}!</strong>
							</Alert>
						) : null}

						<BasicTabs value={projectTab} setValue={setProjectTab} />

						<AddPersonnelModal
							open={open}
							handleClose={handleClose}
							firstName={firstName}
							setFirstName={setFirstName}
							lastName={lastName}
							setLastName={setLastName}
							role={role}
							setRole={setRole}
							classification={classification}
							setClassification={setClassification}
						/>
						<EditPersonnelModal
							open={editModalOpen}
							handleClose={handleEditModalClose}
							person={editModalPerson}
						/>

						<Grid
							container
							spacing={1}
							justifyContent="center"
							wrap="nowrap"
							sx={{ mx: 1, px: 1, py: 0.3, pt: 4 }}
						>
							<Grid
								item
								xs={2}
								sx={{
									backgroundColor: colors.primary[400],
									minWidth: "65px",
									width: isSmallScreen ? "65px" : "80%",
									p: 1,
									my: 0.125,
									display: "flex",
									padding: "5px 10px", // Padding inside the box
									"&:hover": {
										borderColor: "blue", // Border color on hover
										backgroundColor: colors.greenAccent[900],
									},
									"&:focus-within": {
										borderColor: "green", // Border color when focused
									},
								}}
							>
								<CreateWeekEndDropDown
									input={threeWkBk}
									state={weekSelect}
									label="Week Ending"
									onChange={(e) => {
										setWeekSelect(e.target.value);
										const newDate = e.target.value;

										const sunday = dayjs(newDate, "MM/DD/YYYY").weekday(0);
										const monday = dayjs(newDate, "MM/DD/YYYY").weekday(1);
										const tuesday = dayjs(newDate, "MM/DD/YYYY").weekday(2);
										const wednesday = dayjs(newDate, "MM/DD/YYYY").weekday(3);
										const thursday = dayjs(newDate, "MM/DD/YYYY").weekday(4);
										const friday = dayjs(newDate, "MM/DD/YYYY").weekday(5);
										const saturday = dayjs(newDate, "MM/DD/YYYY").weekday(6);
										//change all reference
										//sunday

										setsundayDate(sunday.format("MM/DD/YYYY"));
										setsundayLog(
											updateLogsReferenceDate(sundayLog, sunday, newDate, 0)
										);
										//monday
										setmondayDate(monday.format("MM/DD/YYYY"));
										setmondayLog(
											updateLogsReferenceDate(mondayLog, monday, newDate, 1)
										);
										//tuesday
										settuesdayDate(tuesday.weekday(2).format("MM/DD/YYYY"));
										settuesdayLog(
											updateLogsReferenceDate(tuesdayLog, tuesday, newDate, 2)
										);
										//wednesday
										setwednesdayDate(wednesday.format("MM/DD/YYYY"));
										setwednesdayLog(
											updateLogsReferenceDate(
												wednesdayLog,
												wednesday,
												newDate,
												3
											)
										);
										//thursday
										setthursdayDate(thursday.weekday(4).format("MM/DD/YYYY"));
										setthursdayLog(
											updateLogsReferenceDate(thursdayLog, thursday, newDate, 4)
										);
										//friday
										setfridayDate(friday.weekday(5).format("MM/DD/YYYY"));
										setfridayLog(
											updateLogsReferenceDate(fridayLog, friday, newDate, 5)
										);
										//saturday
										setsaturdayDate(saturday.format("MM/DD/YYYY"));
										setsaturdayLog(
											updateLogsReferenceDate(saturdayLog, saturday, newDate, 6)
										);
									}}
								/>
							</Grid>
							<Grid
								item
								xs={2.5}
								sx={{
									backgroundColor: colors.primary[500],
									minWidth: "120px",
									p: 1,
									my: 0.125,
									display: "flex",
									padding: "5px 10px", // Padding inside the box
									"&:hover": {
										borderColor: "blue", // Border color on hover
										backgroundColor: colors.greenAccent[900],
									},
									"&:focus-within": {
										borderColor: "green", // Border color when focused
									},
								}}
							>
								<CreateProjectDropDownComponent
									error={project1Error}
									data={projectList}
									state={project1}
									label="Project"
									callBack={setProject1}
									id="project1"
								/>
							</Grid>
							<Grid item xs={2.5} sx={showProject2}>
								<CreateProjectDropDownComponent
									error={project2Error}
									data={projectList}
									state={project2}
									label="Project"
									callBack={setProject2}
									id="project2"
								/>
							</Grid>
							<Grid item xs={2.5} sx={showProject3}>
								<CreateProjectDropDownComponent
									error={project3Error}
									data={projectList}
									state={project3}
									label="Project"
									callBack={setProject3}
									id="project3"
								/>
							</Grid>
							<Grid
								item
								xs={1.5}
								sx={{
									backgroundColor: colors.primary[400],
									minWidth: "50px",
									p: 1,
									my: 0.125,
									display: "flex",
									padding: "5px 10px", // Padding inside the box
									"&:hover": {
										borderColor: "blue", // Border color on hover
										backgroundColor: colors.greenAccent[900],
									},
									"&:focus-within": {
										borderColor: "green", // Border color when focused
									},
								}}
							>
								{personnelList && (
									<CreatePeopleDropDown
										input={personnelList}
										state={peopleDropdown}
										label="Personnel"
										id="people"
									/>
								)}
							</Grid>
						</Grid>

						<PayrollCreateRow
							day="Sunday"
							date={sundayDate}
							alternateColors="true"
							log={sundayLog}
							setLog={setsundayLog}
							format="sunday"
							shorthand="sun"
							showRowColor1={showRowColor1}
							showRowColor2={showRowColor2}
							SunHours={sundayHours}
							setSunHours={setsundayHours}
							seterrState={seterrState}
							seterrMsg={seterrMsg}
						/>
						<PayrollCreateRow
							day="Monday"
							date={mondayDate}
							alternateColors="false"
							log={mondayLog}
							setLog={setmondayLog}
							format="weekday"
							shorthand="mon"
							showRowColor1={showRowColor1}
							showRowColor2={showRowColor2}
							regHours={mondayRegHours}
							setRegHours={setmondayRegHours}
							OTHours={mondayOTHours}
							setOTHours={setmondayOTHours}
							seterrState={seterrState}
							seterrMsg={seterrMsg}
						/>
						<PayrollCreateRow
							day="Tuesday"
							date={tuesdayDate}
							alternateColors="true"
							log={tuesdayLog}
							setLog={settuesdayLog}
							format="weekday"
							shorthand="tue"
							showRowColor1={showRowColor1}
							showRowColor2={showRowColor2}
							regHours={tuesdayRegHours}
							setRegHours={settuesdayRegHours}
							OTHours={tuesdayOTHours}
							setOTHours={settuesdayOTHours}
							seterrState={seterrState}
							seterrMsg={seterrMsg}
						/>
						<PayrollCreateRow
							day="Wednesday"
							date={wednesdayDate}
							alternateColors="false"
							log={wednesdayLog}
							setLog={setwednesdayLog}
							format="weekday"
							shorthand="wed"
							showRowColor1={showRowColor1}
							showRowColor2={showRowColor2}
							regHours={wednesdayRegHours}
							setRegHours={setwednesdayRegHours}
							OTHours={wednesdayOTHours}
							setOTHours={setwednesdayOTHours}
							seterrState={seterrState}
							seterrMsg={seterrMsg}
						/>
						<PayrollCreateRow
							day="Thursday"
							date={thursdayDate}
							alternateColors="true"
							log={thursdayLog}
							setLog={setthursdayLog}
							format="weekday"
							shorthand="thur"
							showRowColor1={showRowColor1}
							showRowColor2={showRowColor2}
							regHours={thursdayRegHours}
							setRegHours={setthursdayRegHours}
							OTHours={thursdayOTHours}
							setOTHours={setthursdayOTHours}
							seterrState={seterrState}
							seterrMsg={seterrMsg}
						/>
						<PayrollCreateRow
							day="Friday"
							date={fridayDate}
							alternateColors="false"
							log={fridayLog}
							setLog={setfridayLog}
							format="weekday"
							shorthand="fri"
							showRowColor1={showRowColor1}
							showRowColor2={showRowColor2}
							regHours={fridayRegHours}
							setRegHours={setfridayRegHours}
							OTHours={fridayOTHours}
							setOTHours={setfridayOTHours}
							seterrState={seterrState}
							seterrMsg={seterrMsg}
						/>
						<PayrollCreateRow
							day="Saturday"
							date={saturdayDate}
							alternateColors="true"
							log={saturdayLog}
							setLog={setsaturdayLog}
							format="saturday"
							shorthand="sat"
							showRowColor1={showRowColor1}
							showRowColor2={showRowColor2}
							SatHours={saturdayHours}
							setSatHours={setsaturdayHours}
							seterrState={seterrState}
							seterrMsg={seterrMsg}
						/>

						<Grid
							container
							spacing={1}
							justifyContent="center"
							wrap="nowrap"
							sx={{ mx: 1, px: 1, py: 0.3 }}
						>
							{/* Start of Timecard Settings Width */}
							<Grid
								item
								xs={2}
								sx={{
									backgroundColor: colors.grey[800],
									minWidth: "65px",

									p: 1,
									my: 0.125,
									display: "flex",
									padding: "5px 10px", // Padding inside the box
									"&:hover": {
										borderColor: "blue", // Border color on hover
										backgroundColor: colors.greenAccent[900],
									},
									"&:focus-within": {
										borderColor: "green", // Border color when focused
									},
								}}
							>
								<Box>
									<CreateRadioGroup
										value={radioSelection}
										handleChange={handleRadioChange}
									/>
								</Box>
							</Grid>
							<Grid
								item
								xs={2.5}
								sx={{
									backgroundColor: colors.grey[900],
									minWidth: "120px",
									p: 1,
									my: 0.125,
									display: "flex",
									borderRadius: "4px", // Rounded corners
									padding: "5px 10px", // Padding inside the box
									"&:hover": {
										borderColor: "blue", // Border color on hover
										backgroundColor: colors.greenAccent[900],
									},
									"&:focus-within": {
										borderColor: "green", // Border color when focused
									},
								}}
							>
								{loadingHours ? null : (
									<Stack>
										<Stack direction="row">
											<Typography variant="body2" sx={{ px: 1 }}>
												{" "}
												{"Reg: "}
											</Typography>
											<Typography
												variant="body2"
												sx={{
													color: details?.analytics[0]?.regTotal
														? colors.greenAccent[500]
														: colors.redAccent[500],
												}}
											>
												{details?.analytics[0]?.regTotal
													? details?.analytics[0]?.regTotal
													: 0}
											</Typography>
											<Typography variant="body2" sx={{ px: 1 }}>
												{" OT: "}
											</Typography>
											<Typography
												variant="body2"
												sx={{
													color: details?.analytics[0]?.OTTotal
														? colors.greenAccent[500]
														: colors.redAccent[500],
												}}
											>
												{details?.analytics[0]?.OTTotal
													? details?.analytics[0]?.OTTotal
													: 0}
											</Typography>
										</Stack>
										<Stack direction="row">
											<Typography variant="body2" sx={{ px: 1 }}>
												{" Sat: "}
											</Typography>
											<Typography
												variant="body2"
												sx={{
													color: details?.analytics[0]?.satTotal
														? colors.greenAccent[500]
														: colors.redAccent[500],
												}}
											>
												{details?.analytics[0]?.satTotal
													? details?.analytics[0]?.satTotal
													: 0}
											</Typography>
											<Typography variant="body2" sx={{ px: 1 }}>
												{" "}
												{" Sun: "}
											</Typography>
											<Typography
												variant="body2"
												sx={{
													color: details?.analytics[0]?.sunTotal
														? colors.greenAccent[500]
														: colors.redAccent[500],
												}}
											>
												{details?.analytics[0]?.sunTotal
													? details?.analytics[0]?.sunTotal
													: 0}
											</Typography>
										</Stack>
										<Stack direction="row">
											<Typography variant="body2" sx={{ px: 1 }}>
												{" "}
												{"Subtotal: "}
											</Typography>
											<Typography
												variant="body2"
												sx={{
													color: isNaN(
														details?.analytics[0]?.regTotal +
															details?.analytics[0]?.OTTotal +
															details?.analytics[0]?.satTotal +
															details?.analytics[0]?.sunTotal
													)
														? colors.redAccent[500]
														: colors.greenAccent[500],
												}}
											>
												{isNaN(
													details?.analytics[0]?.regTotal +
														details?.analytics[0]?.OTTotal +
														details?.analytics[0]?.satTotal +
														details?.analytics[0]?.sunTotal
												)
													? 0
													: details?.analytics[0]?.regTotal +
													  details?.analytics[0]?.OTTotal +
													  details?.analytics[0]?.satTotal +
													  details?.analytics[0]?.sunTotal}
											</Typography>
										</Stack>
									</Stack>
								)}
							</Grid>
							<Grid item xs={2.5} sx={showBottomRow1}>
								{loadingHours ? null : (
									<Stack>
										<Stack direction="row">
											<Typography
												variant="body2"
												sx={{ px: 1, color: colors.grey[100] }}
											>
												{" "}
												{"Reg: "}
											</Typography>
											<Typography
												variant="body2"
												sx={{
													color: details?.analytics[1]?.regTotal
														? colors.greenAccent[500]
														: colors.redAccent[500],
												}}
											>
												{details?.analytics[1]?.regTotal
													? details?.analytics[1]?.regTotal
													: 0}
											</Typography>

											<Typography variant="body2" sx={{ px: 1 }}>
												{" OT: "}
											</Typography>

											<Typography
												variant="body2"
												sx={{
													color: details?.analytics[1]?.OTTotal
														? colors.greenAccent[500]
														: colors.redAccent[500],
												}}
											>
												{details?.analytics[1]?.OTTotal
													? details?.analytics[1]?.OTTotal
													: 0}
											</Typography>
										</Stack>
										<Stack direction="row">
											<Typography variant="body2" sx={{ px: 1 }}>
												{" Sat: "}
											</Typography>
											<Typography
												variant="body2"
												sx={{
													color: details?.analytics[1]?.satTotal
														? colors.greenAccent[500]
														: colors.redAccent[500],
												}}
											>
												{details?.analytics[1]?.satTotal
													? details?.analytics[1]?.satTotal
													: 0}
											</Typography>
											<Typography variant="body2" sx={{ px: 1 }}>
												{" "}
												{" Sun: "}
											</Typography>
											<Typography
												variant="body2"
												sx={{
													color: details?.analytics[1]?.sunTotal
														? colors.greenAccent[500]
														: colors.redAccent[500],
												}}
											>
												{details?.analytics[1]?.sunTotal
													? details?.analytics[1]?.sunTotal
													: 0}
											</Typography>
										</Stack>
										<Stack direction="row">
											<Typography variant="body2" sx={{ px: 1 }}>
												{" "}
												{"Subtotal: "}
											</Typography>
											<Typography
												variant="body2"
												sx={{
													color: isNaN(
														details?.analytics[1]?.regTotal +
															details?.analytics[1]?.OTTotal +
															details?.analytics[1]?.satTotal +
															details?.analytics[1]?.sunTotal
													)
														? colors.redAccent[500]
														: colors.greenAccent[500],
												}}
											>
												{isNaN(
													details?.analytics[1]?.regTotal +
														details?.analytics[1]?.OTTotal +
														details?.analytics[1]?.satTotal +
														details?.analytics[1]?.sunTotal
												)
													? 0
													: details?.analytics[1]?.regTotal +
													  details?.analytics[1]?.OTTotal +
													  details?.analytics[1]?.satTotal +
													  details?.analytics[1]?.sunTotal}
											</Typography>
										</Stack>
									</Stack>
								)}
							</Grid>
							<Grid item xs={2.5} sx={showBottomRow2}>
								{loadingHours ? null : (
									<Stack>
										<Stack direction="row">
											<Typography
												variant="body2"
												sx={{ px: 1, color: colors.grey[100] }}
											>
												{" "}
												{"Reg: "}
											</Typography>
											<Typography
												variant="body2"
												sx={{
													color: details?.analytics[2]?.regTotal
														? colors.greenAccent[500]
														: colors.redAccent[500],
												}}
											>
												{details?.analytics[2]?.regTotal
													? details?.analytics[2]?.regTotal
													: 0}
											</Typography>
											<Typography variant="body2" sx={{ px: 1 }}>
												{" OT: "}
											</Typography>
											<Typography
												variant="body2"
												sx={{
													color: details?.analytics[2]?.OTTotal
														? colors.greenAccent[500]
														: colors.redAccent[500],
												}}
											>
												{details?.analytics[2]?.OTTotal
													? details?.analytics[2]?.OTTotal
													: 0}
											</Typography>
										</Stack>
										<Stack direction="row">
											<Typography variant="body2" sx={{ px: 1 }}>
												{" Sat: "}
											</Typography>
											<Typography
												variant="body2"
												sx={{
													color: details?.analytics[2]?.satTotal
														? colors.greenAccent[500]
														: colors.redAccent[500],
												}}
											>
												{details?.analytics[2]?.satTotal
													? details?.analytics[2]?.satTotal
													: 0}
											</Typography>
											<Typography variant="body2" sx={{ px: 1 }}>
												{" "}
												{" Sun: "}
											</Typography>
											<Typography
												variant="body2"
												sx={{
													color: details?.analytics[2]?.sunTotal
														? colors.greenAccent[500]
														: colors.redAccent[500],
												}}
											>
												{details?.analytics[2]?.sunTotal
													? details?.analytics[2]?.sunTotal
													: 0}
											</Typography>
										</Stack>
										<Stack direction="row">
											<Typography variant="body2" sx={{ px: 1 }}>
												{" "}
												{"Subtotal: "}
											</Typography>
											<Typography
												variant="body2"
												sx={{
													color: isNaN(
														details?.analytics[2]?.regTotal +
															details?.analytics[2]?.OTTotal +
															details?.analytics[2]?.satTotal +
															details?.analytics[2]?.sunTotal
													)
														? colors.redAccent[500]
														: colors.greenAccent[500],
												}}
											>
												{isNaN(
													details?.analytics[2]?.regTotal +
														details?.analytics[2]?.OTTotal +
														details?.analytics[2]?.satTotal +
														details?.analytics[2]?.sunTotal
												)
													? 0
													: details?.analytics[2]?.regTotal +
													  details?.analytics[2]?.OTTotal +
													  details?.analytics[2]?.satTotal +
													  details?.analytics[2]?.sunTotal}
											</Typography>
										</Stack>
									</Stack>
								)}
							</Grid>
							<Grid
								item
								xs={1.5}
								sx={{
									backgroundColor: colors.grey[800],
									minWidth: "50px",
									p: 1,
									my: 0.125,
									display: "flex",
									borderRadius: "4px", // Rounded corners
									padding: "5px 10px", // Padding inside the box
									"&:hover": {
										borderColor: "blue", // Border color on hover
										backgroundColor: colors.greenAccent[900],
									},
									"&:focus-within": {
										borderColor: "green", // Border color when focused
									},
								}}
							>
								<Stack direction="row" spacing={0.25}>
									<Typography variant="body2"> Total: </Typography>
									<Typography
										variant="body2"
										sx={{ color: colors.greenAccent[500] }}
									>
										{details.regHours +
											details.overtimeHours +
											details.satHours +
											details.sunHours}
									</Typography>
								</Stack>
							</Grid>
						</Grid>
						<Grid container justifyContent="center" spacing={1} sx={{ pt: 2 }}>
							<Grid item xs={1.5}>
								<Button
									variant="contained"
									sx={{ backgroundColor: colors.greenAccent[500] }}
									onClick={handleSubmit}
									endIcon={<SendIcon />}
								>
									Send
								</Button>
							</Grid>
						</Grid>
					</form>
				</Box>
			)}
		</Box>
	);
}

export default PayrollCreatePage;
// details.project1[0].hours[0].grossHoursTotal
