/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Box from "@mui/joy/Box";
import Avatar from "@mui/joy/Avatar";
import Chip from "@mui/joy/Chip";
import Link from "@mui/joy/Link";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListDivider from "@mui/joy/ListDivider";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Dropdown from "@mui/joy/Dropdown";

import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import BlockIcon from "@mui/icons-material/Block";
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { timeCardData } from "../../fakedata/mockData";

console.log(timeCardData);
const listItems = [
	{
		id: "INV-1234",
		date: "Feb 3, 2023",
		status: "Refunded",
		customer: {
			initial: "O",
			name: "Olivia Ryhe",
			email: "olivia@email.com",
		},
	},
	{
		id: "INV-1233",
		date: "Feb 3, 2023",
		status: "Paid",
		customer: {
			initial: "S",
			name: "Steve Hampton",
			email: "steve.hamp@email.com",
		},
	},
	{
		id: "INV-1232",
		date: "Feb 3, 2023",
		status: "Refunded",
		customer: {
			initial: "C",
			name: "Ciaran Murray",
			email: "ciaran.murray@email.com",
		},
	},
	{
		id: "INV-1231",
		date: "Feb 3, 2023",
		status: "Refunded",
		customer: {
			initial: "M",
			name: "Maria Macdonald",
			email: "maria.mc@email.com",
		},
	},
	{
		id: "INV-1230",
		date: "Feb 3, 2023",
		status: "Cancelled",
		customer: {
			initial: "C",
			name: "Charles Fulton",
			email: "fulton@email.com",
		},
	},
	{
		id: "INV-1229",
		date: "Feb 3, 2023",
		status: "Cancelled",
		customer: {
			initial: "J",
			name: "Jay Hooper",
			email: "hooper@email.com",
		},
	},
];

function RowMenu() {
	return (
		<Dropdown>
			<MenuButton
				slots={{ root: IconButton }}
				slotProps={{ root: { variant: "plain", color: "neutral", size: "sm" } }}
			>
				<MoreHorizRoundedIcon />
			</MenuButton>
			<Menu size="sm" sx={{ minWidth: 140 }}>
				<MenuItem>Edit</MenuItem>
				<MenuItem>Rename</MenuItem>
				<MenuItem>Move</MenuItem>
				<Divider />
				<MenuItem color="danger">Delete</MenuItem>
			</Menu>
		</Dropdown>
	);
}

const TimecardList = () => {
	return (
		<Box sx={{ display: { xs: "block", sm: "none" } }}>
			{timeCardData.map((listItem) => (
				<List key={listItem.id} size="sm" sx={{ "--ListItem-paddingX": 0 }}>
					<ListItem
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "start",
						}}
					>
						<ListItemContent
							sx={{ display: "flex", gap: 2, alignItems: "start" }}
						>
							<ListItemDecorator>
								<Avatar size="sm">{listItem?.customer?.initial || "C"}</Avatar>
							</ListItemDecorator>
							<div>
								<Typography gutterBottom sx={{ fontWeight: 600 }}>
									{listItem.name}
								</Typography>
								<Typography level="body-xs" gutterBottom>
									{listItem.user.email}
								</Typography>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										gap: 0.5,
										mb: 1,
									}}
								>
									<Typography level="body-xs">{listItem.createdAt}</Typography>
									<Typography level="body-xs">&bull;</Typography>
									<Typography level="body-xs">
										{listItem?.id || "ID #"}
									</Typography>
								</Box>
								<Box
									sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}
								>
									<Link level="body-sm" component="button">
										Download
									</Link>
									<RowMenu />
								</Box>
							</div>
						</ListItemContent>
						<Chip
							variant="soft"
							size="sm"
							startDecorator={
								{
									Complete: <CheckRoundedIcon />,
									Refunded: <AutorenewRoundedIcon />,
									Incomplete: <BlockIcon />,
								}[listItem.status]
							}
							color={
								{
									Complete: "success",
									Refunded: "neutral",
									Incomplete: "danger",
								}[listItem.status]
							}
						>
							{listItem.status}
						</Chip>
					</ListItem>
					<ListDivider />
				</List>
			))}
			<Box
				className="Pagination-mobile"
				sx={{
					display: { xs: "flex", md: "none" },
					alignItems: "center",
					py: 2,
				}}
			>
				<IconButton
					aria-label="previous page"
					variant="outlined"
					color="neutral"
					size="sm"
				>
					<KeyboardArrowLeftIcon />
				</IconButton>
				<Typography level="body-sm" sx={{ mx: "auto" }}>
					Page 1 of 10
				</Typography>
				<IconButton
					aria-label="next page"
					variant="outlined"
					color="neutral"
					size="sm"
				>
					<KeyboardArrowRightIcon />
				</IconButton>
			</Box>
		</Box>
	);
};

export default TimecardList;
