import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthProvider";
import ChangeOrders from "../changeorders";
import { Box } from "@mui/material";
import TopBarAdmin from "../components/TopBarAdmin";
import Alert from "@mui/material/Alert";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";

function AdminPageChangeOrders() {
	const { api } = useAuth();
	//load all user data
	//const { data, loading, error } = useFetch('/api/v1/projects');

	const [changeOrders, setchangeOrders] = useState([]);
	const [selectedDataID, setselectedDataID] = useState([]);
	const [notificationsNumber, setnotificationsNumber] = useState(0);
	const [project, setProject] = useState("All Projects");
	const [projectList, setProjectList] = useState([]);
	const [errState, setErrState] = useState(false);
	const [errMsg, seterrMsg] = useState("");
	const [listData, setListData] = useState([]);

	function checkFailed(then) {
		return function (responses) {
			const someFailed = responses.some((response) => response.error);

			if (someFailed) {
				throw responses;
			}

			return then(responses);
		};
	}

	useEffect(() => {
		if (api.authenticated) {
			async function GetCO() {
				await api
					.get(`/api/v1/changeorder/?page=1&limit=20&project=${project}`)
					.catch(() => console.log("Error"))
					.then((data) => {
						// console.log(data);
						// if (data?.data[0]?.results) {
						//   setchangeOrders(data.data[0].results);
						// }
					});
			}
			GetCO();
		}
	}, [api, project]);

	useEffect(() => {
		if (api.authenticated) {
			async function GetProjectList(data) {
				await api
					.get(`/api/v1/projects/list`)
					.catch((err) => {
						console.log("Error", err);
						setErrState(true);
						seterrMsg("Error Loading Data");
					})
					.then((response) => {
						setErrState(false);
						seterrMsg("");
						console.log(response);
						if (response?.data?.data) {
							setProjectList(response.data.data);
							setProject(response.data.data[0]);
						}
					});
			}
			GetProjectList();
		}
	}, [api]);

	useEffect(() => {
		if (api.authenticated) {
			Promise.all([
				api.get("/api/v1/projects/list"),
				api.get("/api/v1/personnel/?sort=fullName"),
			])
				.then(
					checkFailed(([projects, personnel]) => {
						setListData((prev) => ({
							...prev,
							projects,
							personnel,
						}));
					})
				)
				.catch((err) => {
					console.log("FAIL", err);
					setErrState(true);
					seterrMsg("Error Loading Data");
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api?.authenticated]);

	useEffect(() => {
		if (api.authenticated) {
			async function GetResults() {
				try {
					const response = await api.get(
						`/api/v1/timecardsv2/unapproved/?page=1&limit=1000`
					);
					console.log(response);
					setnotificationsNumber(response.data.data[0].results.length);
				} catch (err) {
					console.log("Failed", { err });
					setErrState(true);
					seterrMsg("Error Loading Data");
				}
			}

			GetResults();
		}
	}, [api]);

	// //Delete Many Projects By Id
	// async function deleteManyProjects(...prevProjects) {
	// 	await Promise.all(
	// 		selectedDataID.map((id) =>
	// 			api.delete(`/api/v1/changeorder/${id}`, {
	// 				headers: {
	// 					"Content-Type": "application/json",
	// 					Authorization: `Bearer ${token}`,
	// 				},
	// 				baseURL: baseURL,
	// 			})
	// 		)
	// 	)
	// 		.then(handleRemoveMultipleItems())
	// 		.catch(function (err) {});
	// }

	// const deleteById = (id) => {
	// 	//filter keeps all id thats not selected
	// 	const filteredData = changeOrders.filter((item) => item._id !== id);
	// 	// Do something with the filtered data
	// 	setchangeOrders(filteredData);
	// };

	// const handleRemoveMultipleItems = () => {
	// 	selectedDataID.forEach((id) => deleteById(id));
	// };

	return (
		<Box sx={{ height: "100dvh" }}>
			{errState ? (
				<Alert
					icon={<RunningWithErrorsIcon fontSize="inherit" />}
					severity="error"
				>
					{errMsg}
				</Alert>
			) : null}
			<TopBarAdmin notificationsNumber={notificationsNumber} />
			<ChangeOrders
				selectedDataID={selectedDataID}
				setselectedDataID={setselectedDataID}
				data={changeOrders}
				setchangeOrders={setchangeOrders}
				project={project}
				setProject={setProject}
				projectList={projectList}
				listData={listData}
				//deleteManyProjects={deleteManyProjects}
			/>
		</Box>
	);
}

export default AdminPageChangeOrders;
