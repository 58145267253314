import Timecard from "../timecard";
import * as React from "react";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import TopBarAdmin from "../components/TopBarAdmin";
import usePath from "../hooks/fetchPath";
import dayjs from "dayjs";
import { useAuth } from "../context/AuthProvider";
import Alert from "@mui/material/Alert";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import threeWeekLookBack from "../hooks/three-week-lookback";

function AdminPageTimecards({ auth, user }) {
	const { api, success } = useAuth();
	const [isSidebar, setIsSidebar] = useState(true);
	const [project, setProject] = React.useState("");
	const weeksObject = threeWeekLookBack();
	const [startQry, setStartQry] = React.useState(weeksObject.sixWkBkDesc[0]);
	const [endQry, setEndQry] = React.useState(weeksObject.sixWkBkDesc[6]);
	const [editMode, setEditMode] = React.useState(false);
	const [notificationsNumber, setnotificationsNumber] = useState(0);
	const [pageState, setPageState] = useState({
		isLoading: false,
		data: [],
		total: 0,
		page: 0,
		pageSize: 100,
	});
	const [errState, setErrState] = useState(false);
	const [errMsg, seterrMsg] = useState("");
	const [timecardQryMode, setTimecardQryMode] = useState("range");

	useEffect(() => {
		async function GetResults(data) {
			if (data.length > 0) {
				let results = await data.filter((x) => x.approved === false);
				setnotificationsNumber(results.length);
			}
		}

		if (pageState?.data) {
			GetResults(pageState.data);
		}
	}, [pageState?.data]);

	useEffect(() => {
		async function getTimecards() {
			const pagelimit = `&limit=${pageState.pageSize}`;
			let projectqryRender = "";
			let startQryRender = "";
			let endQryRender = "";
			if (project) {
				let string = JSON.stringify(project);
				projectqryRender = ``;
			}
			if (startQry) {
				let date = dayjs(startQry).format("MM/DD/YYYY");
				startQryRender = new Date(date).toISOString();
			}
			if (endQry) {
				let date2 = dayjs(endQry).format("MM/DD/YYYY");
				endQryRender = new Date(date2).toISOString();
			}

			if (editMode === true) {
				try {
					const response = await api.get(
						`/api/v1/timecardsv2/range?page=${pageState.page + 1}&limit=${
							pageState.pageSize
						}&mode=${timecardQryMode}&start=${startQryRender}&end=${endQryRender}`
					);

					if (response) {
						const update = {
							data: response?.data?.data[0].results,
							pageSize: pageState.pageSize,
							total: response?.data?.data[0].pagination[0]?.totaldocuments,
						};

						setPageState((old) => ({
							...old,
							...update,
						}));

						setErrState(false);
						seterrMsg("");
					}
				} catch (err) {
					console.log(err);
					setErrState(true);
					seterrMsg("Error Loading Data");
				}
			} else if (editMode === false && typeof pagelimit !== "undefined") {
				try {
					const response = await api.get(
						`/api/v1/timecardsv2/unapproved/?page=${pageState.page + 1}` +
							pagelimit
					);

					if (response) {
						const update = {
							data: response?.data?.data[0].results,
							pageSize: pageState.pageSize,
							total: response?.data?.data[0].pagination[0]?.totaldocuments,
						};

						setPageState((old) => ({
							...old,
							...update,
						}));
						setErrState(false);
						seterrMsg("");
					}
				} catch (err) {
					console.log(err);
					setErrState(true);
					seterrMsg("Error Loading Data");
				}
			}
		}
		getTimecards();
	}, [
		api,
		editMode,
		endQry,
		pageState.page,
		pageState.pageSize,
		project,
		startQry,
		success,
		timecardQryMode,
	]);

	return (
		<div>
			<Box sx={{ height: "100dvh" }}>
				{errState ? (
					<Alert
						icon={<RunningWithErrorsIcon fontSize="inherit" />}
						severity="error"
					>
						{errMsg}
					</Alert>
				) : null}
				<TopBarAdmin
					notificationsNumber={notificationsNumber}
					setIsSidebar={setIsSidebar}
				/>

				<Timecard
					user={user}
					project={project}
					setProject={setProject}
					startQry={startQry}
					setStartQry={setStartQry}
					endQry={endQry}
					setEndQry={setEndQry}
					pageState={pageState}
					setPageState={setPageState}
					editMode={editMode}
					setEditMode={setEditMode}
				/>
			</Box>
		</div>
	);
}

export default AdminPageTimecards;
